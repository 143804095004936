import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Cheque3.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LanguageVariant } from 'typescript';


const ChequeManage3 = () => {
    const goToChequeSecondPage = () => {
        navigate('/Cheque2');
    }

    return (
        <div id="cheque-container3">
            <ReceiptHeader />
            <section>
                <div className="content-container" id="font">
                    <div className="content-title">
                        <p>Cheque Management</p>
                    </div>
                    <p>Cheque Management/<b>Re-Print</b></p>
                    <div className="content-box payee-details">
                    <p className="title">Payment Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label >Payout Type</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" >
                                        <option value="">--- SelectPayout Type ---</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label >Bank Name</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" >
                                        <option value="">--- Select Bank Name ---</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label >Account Code</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" >
                                        <option value="">--- Select Account Code ---</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Bank Code</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="" value="" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>RC Code</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeName" name="PayeeName" placeholder="0.00" value="" />
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <div className="content-box pay-cheque-details">
                        <p className="title">Payment Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Pay By*</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter here" value="" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label> Type of Pay Cheque*</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter here" value="" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Amount</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="0.00" value="" />
                                </div>
                                <br></br>
                            </div>
                            <div className="form-group">
                                <label id="cheque1">&nbsp;&nbsp; Check Number</label>
                                <br></br>
                                <div className="col-sm-1 mb-1 float-left">
                                    <input type="text" className="form-control form-text" id="cheque1" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-sm-1 mb-1 float-left">
                                    <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-sm-1 mb-1 float-left">
                                    <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-md-5 mb-2 float-left">
                                    <select className="form-control" name="search_by" id="search_by">
                                        <option value="">Specific</option>
                                    </select>
                                </div>
                                <div className="col-md-3 mb-1 float-right">
                                    <input type="text" className="form-control form-text" id="cheque1" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-sm-1 mb-1 float-right">
                                    <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <br></br>
                                <br></br>
                            </div>
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Cheque Date</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter here" value="" />
                                </div>
                                <div className="col-sm-1 mb-1 float-left">
                                    <label>&nbsp;&nbsp;</label>
                                    <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-md-3 mb-2 float-left">
                                    <label >Pickup Cheque at Branch</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" >
                                        <option value="">HO0 : Lorem Ipsum dorem sit</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>
                                <br></br>
                                <br></br>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Payee Name*</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter here" value=""/>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Name of Document*</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter here" value="" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Old Document Number*</label>
                                    <input type="text" className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter Here..." value="" />
                                </div>
                                <br></br>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control form-text"id="PayeeCode" name="PayeeCode" placeholder="Enter here" value=""/>
                                </div>
                                <br></br>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                    </div>


                    {<div className="bottom">
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="form-group">
                                <div className="col-md-2 mb-2 float-right">
                                <button id="receipt-search" className="primary-button" onClick={goToChequeSecondPage} >Submit</button>
                                </div>
                                <div className="col-md-2 mb-2 float-right">
                                <button id="clear-filter" className="btn btn-outline-dark" onClick={goToChequeSecondPage} >Cancel</button>
                                </div>
                        </div>
                      </div>
                    }
                </div>
            </section>
        </div>
    )

}

export default ChequeManage3;