import { ENV, DOMAIN, PFurl } from './env';

console.log("ENV:"+ENV)
console.log("DOMAIN:"+DOMAIN)

const CONFIG = {
    local: {
        getPFData: `${PFurl}`,
        getReceipt: `${DOMAIN}/finance/v1/receipt/`,
        createReceipt: `${DOMAIN}/finance/v1/receipt`,
        applyPremium: `${DOMAIN}/finance/v1/applypremium`,
        getVoucher: `${DOMAIN}/finance/v1/paymentvoucher/`,
        createVoucher: `${DOMAIN}/finance/v1/paymentvoucher`
    },
    dev: {
        getPFData: `${PFurl}`,
        getReceipt: `${DOMAIN}/finance/v1/receipt/`,
        createReceipt: `${DOMAIN}/finance/v1/receipt`,
        applyPremium: `${DOMAIN}/finance/v1/applypremium`,
        getVoucher: `${DOMAIN}/finance/v1/paymentvoucher/`,
        createVoucher: `${DOMAIN}/finance/v1/paymentvoucher`
    },
    poc: {
        getPFData: `${PFurl}`,
        getReceipt: `${DOMAIN}/finance/v1/receipt/`,
        createReceipt: `${DOMAIN}/finance/v1/receipt`,
        applyPremium: `${DOMAIN}/finance/v1/applypremium`,
        getVoucher: `${DOMAIN}/finance/v1/paymentvoucher/`,
        createVoucher: `${DOMAIN}/finance/v1/paymentvoucher`
    },
    boltechDev: {
        getPFData: `${PFurl}`,
        getReceipt: `${DOMAIN}/finance/v1/receipt/`,
        createReceipt: `${DOMAIN}/finance/v1/receipt`,
        applyPremium: `${DOMAIN}/finance/v1/applypremium`,
        getVoucher: `${DOMAIN}/finance/v1/paymentvoucher/`,
        createVoucher: `${DOMAIN}/finance/v1/paymentvoucher`
    }
};

const endPoint = CONFIG[ENV]

export default endPoint;