import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
// import { Calendar } from 'antd';
import ReceiptHeader from '../ReceiptHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllVouchers.css';
// import EditableTable from './EditableTable';

const AllVouchers = () => {

    interface receiptLineItemsProp {
        DepositeAmount: number;
    }
    interface receiptProps {
        ReceiptNumber: string;
        ReceiptDetail: receiptLineItemsProp[];
        PayeeCode: string;
        PayeeName: string;
        ReceiptDate: string;
        ReceiptStatus: string;
        Amount: number;
    }

    let tReceiptsList1: receiptProps = {
        ReceiptNumber: "",
        ReceiptDetail: [],
        PayeeCode: "",
        PayeeName: "",
        ReceiptDate: "",
        ReceiptStatus: "",
        Amount: 0
    };

    let formData = {
        search_value: "",
        search_by: "",
        search_from_date: "",
        search_to_date: ""
    };



    const [receiptsList, setReceiptsList] = useState<any[]>([]);
    // const [tReceiptsList, setTReceiptsList] = useState<receiptProps>(tReceiptsList1);

    // const goToReceiptDetail = () => {
    //     navigate('/ViewReceipt');
    // };

    useEffect(() => {
        // setReceiptsList(tReceiptsList);
    }, [receiptsList]);

    const handleUpdate = (e: any, isReceipt = true) => {
        const { name, value } = e.target;
        // @ts-ignore
        formData[name] = value;
    }

    const testFun = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ReceiptDetail.map((el: any) => {
            console.log(el);
            newData.Amount = newData.Amount + el.DepositeAmount;
            return el;
        });
        // setTReceiptsList(newData);
    }

    const searchReceipt = () => {
        if (formData.search_by === 'Receipt_Number') {
            try {
                fetch(`https://general.sonic.dev.coherent.com.hk/general/v2/finance/receipt/${formData.search_value}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        tReceiptsList1 = response;
                        // const pList = receiptsList;
                        //tReceiptsList.push(response);
                        //tReceiptsList.push(response);
                        // pList.push(response);
                        //debugger;
                        // @ts-ignore;
                        window.receiptData = response;
                        // setTReceiptsList(tReceiptsList1);
                        testFun(tReceiptsList1);
                    });
            } catch (exception) {
                console.log(exception);
            }
        }
    }

    const clearFilter = () => {
        setReceiptsList([]);
        formData = {
            search_value: "",
            search_by: "",
            search_from_date: "",
            search_to_date: ""
        };
    }

    return (
        <div id="modify-voucher-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-title">
                        <p>All Vouchers <button id="receipt-search" className="btn btn-outline-dark float-right">+Create New</button></p>
                    </div>
                    <br />
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <span className="nav-link active">All</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link" aria-current="page">Draft</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link">Awaiting Approval</span>
                            </li>
                        </ul>
                    </div>
                    <br /><br />
                    <div className="content-box payee-details">
                        <p className="title">Search Voucher</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Search Voucher</label>
                                    <input type="text" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_value" name="search_value" placeholder="Begin your search" />
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label >&nbsp;</label>
                                    <select className="form-control" name="search_by" id="search_by" onChange={(e) => handleUpdate(e, true)}>
                                        <option value="">Search By</option>
                                        <option value="Receipt_Number">Receipt Number</option>
                                        <option value="Payee_Code">Payee Code</option>
                                    </select>
                                </div>
                                <div className="float-left or-container">
                                    <p className="">Or</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Date Range</label>
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_from_date" name="search_from_date" placeholder="YYYY/MM/DD" />
                                </div>
                                <div className="float-left or-container">
                                    <p className="">To</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left search-to">
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_to_date" name="search_to_date" placeholder="YYYY/MM/DD" />
                                    {/* <div className="site-calendar-demo-card"> <Calendar fullscreen={false} /> </div> */}
                                </div>
                                <div className="col-md-3 mb-2 float-left search-to">
                                    <button id="receipt-search" className="primary-button" onClick={searchReceipt}>Search</button>
                                    <button id="clear-filter" className="btn btn-outline-dark" onClick={clearFilter}>Clear filter</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                            <div className="col-md-12 mb-2 title ">
                                <p> (50) Vouchers </p>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Voucher #</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payee Type</th>
                                            <th scope="col">Payee Code</th>
                                            <th scope="col">Payee Name</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Payout Type</th>
                                            <th scope="col">Cost/Profit Centre</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/*
                                            tReceiptsList.ReceiptNumber !== "" && (
                                                <tr>
                                                    <td className="receipt-number" onClick={goToReceiptDetail}><input type="checkbox" id="check1"></input>&nbsp;{tReceiptsList.ReceiptNumber}</td>
                                                    <td>{tReceiptsList.Amount}</td>
                                                    <td>{tReceiptsList.PayeeCode}</td>
                                                    <td>{tReceiptsList.PayeeName}</td>
                                                    <td>{tReceiptsList.ReceiptDate}</td>
                                                    <td>{tReceiptsList.ReceiptStatus}</td>
                                                </tr>
                                            )
                                        }
                                        {/* {
                                            tReceiptsList.ReceiptNumber !== "" && receiptsList.map((receipt, index) => (
                                                <tr>
                                                    <td><input type="checkbox" id="check1"></input>&nbsp;{receipt.ReceiptNumber}</td>
                                                    <td>{receipt.ReceiptDetail[0].DepositeAmount}</td>
                                                    <td>{receipt.PayeeCode}</td>
                                                    <td>{receipt.PayeeName}</td>
                                                    <td>{receipt.ReceiptDate}</td>
                                                    <td>{receipt.ReceiptStatus}</td>
                                                </tr>
                                            ))

                                        } */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div className="footer">
                            <div className="dropdown">
                                Rows per page
                        <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                    <li><span></span></li>
                                    <li><span></span></li>
                                </ul>
                        &nbsp;&nbsp;
                        1-10 of 15
                        &nbsp;&nbsp;
                        <span className="previous">&#8249;</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="next">&#8250;</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                        </div>
                    </div>
                </div>
                {/* <div className="bottom">
                    <button id="continue">Create Receipt</button>
                </div> */}
            </section>
        </div>
    )
}

export default AllVouchers;
