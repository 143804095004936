import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Cheque.css';

const ChequeManage = () => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
    });
    interface searchDataProps {
        payee_name: string;
    }

    let formData: searchDataProps = {
        payee_name: ""
    };

    interface PayoutProps {
        BankName: string;
        AccountNumber: string;
    }

    interface voucherProps {
        PaymentDate: string;
        PVNumber: string;
        PayeeName: string;
        PVAmount: string;
        PayoutDetails: PayoutProps;
        PaymentItem: string;
        isSelected?: boolean;
        Amount?: number;
    }

    const [searchData, setSearchData] = useState<searchDataProps>(formData);
    const [voucherList, setVoucherList] = useState<voucherProps[]>([]);

    const redirecttocheque2 = (index: number) => {
        //@ts-ignore
        window.ChequeVoucherSelected = voucherList[index];
        navigate('/Cheque2');
    }

    const handleUpdate = (e: any, isReceipt = true) => {
        const { name, value } = e.target;
        const newFormData = { ...searchData };
        // @ts-ignore
        newFormData[name] = value;
        setSearchData(newFormData);
    }

    const calculateAmount = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ItemDetails.map((el: any) => {
            newData.Amount = newData.Amount + el.ItemAmount;
        });
        return newData;
    }

    const searchReceipt = () => {
        const searchStr = `${searchData.payee_name}`;
        if (searchData.payee_name) {
            try {
                fetch(`${endPoint.getVoucher}PayeeName/${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        const vouherArr = response.map((voucher: voucherProps) => {
                            voucher.isSelected = false;
                            return calculateAmount(voucher);
                        });
                        setVoucherList(vouherArr);
                    });
            } catch (exception) {
                console.log(exception);
            }
        }
    }

    const handleCheckBoxChange = (e: any, rNo: string) => {
        const { checked } = e.target;
        const newVoucherList = [...voucherList];
        const selectedVoucher: voucherProps[] = [];
        newVoucherList.map(rl => {
            if (rl.PVNumber === rNo || rNo === 'ALL') {
                rl.isSelected = checked;
                selectedVoucher.push(rl);
            }
        });
        setVoucherList(newVoucherList);
    }

    console.log('this is render', voucherList);

    return (
        <div id="cheque-container">
            <ReceiptHeader />
            <section>
                <div className="content-container" id="font">
                    <div className="content-title">
                        <p>Cheque Management</p>
                    </div>


                    <div className="content-box payee-details">
                        <p className="title">Search</p>
                        <div className="">

                            <div className="form-group">
                                <div className="col-sm-8 mb-1 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Bank - Branch</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-1 mb-1 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-1 mb-1 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-8 mb-8 float-left remove-left-pad">
                                            <select className="form-control form-text" name="account_name" id="account_name">
                                                <option value="">--Select Account--</option>
                                                <option value="1">Option 1</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Cheque Number</label>
                                    </div>
                                    <div className="col-sm-2 mb-2 float-left remove-left-pad">
                                        <input type="text" className="form-control form-text" id="cheque_number_title" name="cheque_number_title" placeholder="-" />
                                    </div>
                                    <div className="col-sm-10 mb-10 float-left remove-left-pad">
                                        <input type="text" className="form-control form-text" id="cheque_number" name="cheque_number" placeholder="Enter Cheque Number" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-4 mb-1 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Payee Name</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <input type="text" className="form-control form-text" id="payee_name" name="payee_name"
                                            onChange={(e) => handleUpdate(e)} placeholder="Enter Payee Name" />
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Old Document Number</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 float-left remove-left-pad">
                                        <input type="text" className="form-control form-text" id="name_on_cheque" name="name_on_cheque" placeholder="Enter here" />
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Old Document Number</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 float-left remove-left-pad">
                                        <input type="text" className="form-control form-text" id="name_on_cheque" name="name_on_cheque" placeholder="Enter here" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-4 mb-1 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Cheque Date</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-5 mb-5 remove-left-pad float-left">
                                            <input type="date" className="form-control form-text" id="from_date" name="from_date" />
                                        </div>
                                        <div className="col-sm-2 mb-2 remove-left-pad float-left">
                                            To
                                        </div>
                                        <div className="col-sm-5 mb-5 remove-left-pad float-left">
                                            <input type="date" className="form-control form-text" id="to_date" name="to_date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Pickup cheque at Branch</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-2 mb-2 remove-left-pad float-left">
                                            <input type="date" className="form-control form-text" id="branch_code" name="branch_code" placeholder="234" />
                                        </div>
                                        <div className="col-sm-10 mb-10 remove-left-pad float-left">
                                            <select className="form-control form-text" id="branch_name" name="branch_name">
                                                <option value="">Select branch</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-12 mb-12 float-left">
                                    <div className="col-md-1 mb-1 float-right btn-2-div">
                                        <button id="clear-filter" className="btn secondary-button btn-2">Clear</button>
                                    </div>
                                    <div className="col-md-1 mb-1 float-right btn-2-div">
                                        <button id="banktrans-search" className="primary-button" onClick={searchReceipt}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                            <div className="col-md-4 mb-1 float-right filter-container">
                                <div className="col-md-4 mb-4 float-left filter-container-title">
                                    <label>Payee Account #</label>
                                </div>
                                <div className="col-md-8 mb-8 float-left">
                                    <input type="text" className="form-control form-text" id="filter_payee_account" name="filter_payee_account" placeholder="begin Your Search" />
                                </div>
                            </div>
                            <div className="col-md-4 mb-1 float-right filter-container">
                                <div className="col-md-4 mb-4 float-left filter-container-title">
                                    <label>Payment Voucher#</label>
                                </div>
                                <div className="col-md-8 mb-8 float-left">
                                    <input type="text" className="form-control form-text" id="filter_payment_voucher" name="filter_payment_voucher" placeholder="begin Your Search" />
                                </div>
                            </div>
                            <div className="col-md-12 mb-1 title ">
                                <p>{voucherList.length} Record(s) Found</p>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input type="checkbox" name="checkAll" id="checkAll"
                                                    onChange={(e) => handleCheckBoxChange(e, 'ALL')} />
                                            </th>
                                            <th scope="col">Bank-Branch</th>
                                            <th scope="col">Due Date</th>
                                            <th scope="col">Payment Voucher #</th>
                                            <th scope="col">Payee Account #</th>
                                            <th scope="col">Payee Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payment Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voucherList.map((voucher, index) => (
                                                <tr key={`vouher-list-${index}`}>
                                                    <td>
                                                        <input type="checkbox" checked={voucher.isSelected}
                                                            name={`check-${voucher.PVNumber}`} id={`check-${voucher.PVNumber}`}
                                                            onChange={(e) => handleCheckBoxChange(e, voucher.PVNumber)} />
                                                    </td>
                                                    <th onClick={() => redirecttocheque2(index)} className="voucher-number" scope="col" >{voucher.PayoutDetails.BankName}</th>
                                                    <th scope="col">{voucher.PaymentDate}</th>
                                                    <th scope="col" className="voucher-number" onClick={() => redirecttocheque2(index)}>{voucher.PVNumber}</th>
                                                    <th scope="col">{voucher.PayoutDetails.AccountNumber}</th>
                                                    <th scope="col">{voucher.PayeeName}</th>
                                                    <th scope="col">{formatter.format(voucher.Amount)}</th>
                                                    <th scope="col">{voucher.PaymentItem}</th>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="col-md-2 mb-2 float-right">
                        <button id="receipt-search" className="button-common primary-button float-right">Download</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ChequeManage;