import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Review2.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Review2 = () => {

    interface receiptLineItemsProp {
        DepositeBy: string;
        DepositeByNo: string;
        BankCode: string;
        BankName: string;
        BankBranchName: string;
        DepositeRefNo: string;
        DepositeRefType: string;
        DepositeDate: string;
        DepositeAmount: number;
        BankFee: number;
        DepositeType: string;
        Status: string;
        isSelected: boolean;
    }

    interface receiptProps {
        ReceiptNumber: string;
        ReceiptDetail: receiptLineItemsProp[];
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        ReceiptDate: string;
        ReceiptStatus: string;
        Amount: number;
        isSelected: boolean;
    }

    const [tReceiptsList, setTReceiptsList] = useState<receiptProps[]>([]);
    const [selectedReceipts, setSelectedReceipts] = useState<receiptProps[]>([]);
    const [selectedDeposits, setSelectedDeposits] = useState<receiptLineItemsProp[]>([]);
    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");

    useEffect(() => {
        // @ts-ignore
        const selectedR = window.UpdateStatusSelectedReceipts.map(rec => {
            rec.ReceiptDetail.map(r => {
                r.isSelected = false;
                return r;
            });
            return rec;
        });
        setTReceiptsList(selectedR);
    }, []);

    const handleCheckBoxChange = (e: any, rNo: string, ind: number) => {
        const { checked } = e.target;
        const newRList = [...tReceiptsList];
        const selectedReceipts: any[] = [];
        const selectedDeposits: receiptLineItemsProp[] = [];
        newRList.map(rl => {
            if (rl.ReceiptNumber === rNo) {
                rl.ReceiptDetail[ind].isSelected = checked;
            }
            if (rNo === 'ALL') {
                rl.ReceiptDetail.map(r => {
                    r.isSelected = checked;
                    return r;
                })
            }
            let depositAdded = false;
            rl.ReceiptDetail.map(r => {
                if (r.isSelected) {
                    selectedDeposits.push(r);
                    depositAdded = true;
                }
                return r;
            });
            if (depositAdded) {
                selectedReceipts.push(rl);
            }
            return rl;
        });
        setTReceiptsList(newRList);
        setSelectedReceipts(selectedReceipts);
        setSelectedDeposits(selectedDeposits);
    }

    const redirectToNextPage1 = (status: string) => {
        const newReceiptList = tReceiptsList.map(rec => {
            setIsLoading(true);
            if (!rec.isSelected) {
                return rec;
            }
            rec.ReceiptDetail.map((rDeposite, index) => {
                if (rDeposite.isSelected)
                    rDeposite.Status = status;
            });
            const payload = { ...rec };
            //delete payload.isSelected;
            try {
                fetch(`${endPoint.createReceipt}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        setIsLoading(false);
                        let msg = '';
                        if (status === 'Cancel') {
                            msg = 'cancelled';
                        } else if (status === 'Confirm') {
                            msg = 'confirmed';
                        } else if (status === 'Review') {
                            msg = 'reviewed';
                        }
                        setSuccessMessage(msg);
                        setShowSucessModal(true);
                    });
            } catch (exception) {
                console.log(exception);
            }
            return rec;
        });
        setTReceiptsList(newReceiptList);
    };

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }

    return (
        <div id="review-update-container">
            <ReceiptHeader />
            <section>
                <div className="container-4">
                    <p id="a">Modify/Cancel Receipt / <b> Deposite Details</b></p>
                </div>
                <div className="content-box receipt-details">
                    <div className="receipt-table-header">
                        <div className="col-md-2 mb-1 float-right">
                            <label>Reference #</label>&nbsp;
                                <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" placeholder="Enter Reference Number" />
                        </div>
                        <div className="col-md-2 mb-2 float-right">
                            <label >Ref Type</label>
                            <select className="form-control" name="search_by" id="search_by">
                                <option value="">---Select Type---</option>
                                <option value="Receipt_Number">Receipt Number</option>
                                <option value="Payee_Code">Payee Code</option>
                            </select>
                        </div>
                        <div className="col-md-2 mb-1 float-right">
                            <label>Search by receipt #</label>&nbsp;
                                <input type="text" className="form-control form-text" id="search_to_date" name="search_to_date" placeholder="Enter Receipt Number" />
                        </div>
                        <div className="col-md-12 mb-1 title ">
                            <p>Deposite Details</p>
                        </div>
                    </div>
                    <div className="receipt-table-container">
                        <div className="col-md-12 mb-2 float-left middle-table">
                            <table className="table table-striped receipt-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" name="checkAll" id="checkAll"
                                                onChange={(e) => handleCheckBoxChange(e, 'ALL', 0)} />
                                        </th>
                                        <th scope="col">Receipt#</th>
                                        <th scope="col">Deposit By</th>
                                        <th scope="col">Deposit By #</th>
                                        <th scope="col">Bank Code</th>
                                        <th scope="col">Bank Name</th>
                                        <th scope="col">Bank Branch</th>
                                        <th scope="col">Ref Type</th>
                                        <th scope="col">Ref #</th>
                                        <th scope="col">Deposit Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fee</th>
                                        <th scope="col">VAT</th>
                                        <th scope="col">WTH</th>
                                        <th scope="col">Deposit Type</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (tReceiptsList.length > 0) && tReceiptsList.map(rec => (
                                            rec.ReceiptDetail.map((rDeposite, index) => (
                                                <tr key={`receipt-container-${rec.ReceiptNumber}-${index}`}>
                                                    <td>
                                                        <input type="checkbox" checked={rDeposite.isSelected}
                                                            name={`check-${rec.ReceiptNumber}`} id={`check-${rec.ReceiptNumber}`}
                                                            onChange={(e) => handleCheckBoxChange(e, rec.ReceiptNumber, index)} />
                                                    </td>
                                                    <td>{rec.ReceiptNumber}</td>
                                                    <td>{rDeposite.DepositeBy}</td>
                                                    <td>{rDeposite.DepositeByNo}</td>
                                                    <td>{rDeposite.BankCode}</td>
                                                    <td>{rDeposite.BankName}</td>
                                                    <td>{rDeposite.BankBranchName}</td>
                                                    <td>{rDeposite.DepositeRefNo}</td>
                                                    <td>{rDeposite.DepositeRefType}</td>
                                                    <td>{rDeposite.DepositeDate}</td>
                                                    <td>{rDeposite.DepositeAmount}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.DepositeType}</td>
                                                    <td>{rDeposite.Status}</td>
                                                </tr>
                                            ))
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr />
                    <div className="footer">
                        <div className="dropdown">
                            Rows per page
                        <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                                <li><span></span></li>
                                <li><span></span></li>
                            </ul>
                            &nbsp;&nbsp;
                            1-10 of 15
                            &nbsp;&nbsp;
                        <span className="previous">&#8249;</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="next">&#8250;</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    </div>
                </div>
                <div className="bottom">
                    <button id="continue" className="btn btn-outline-primary" onClick={() => redirectToNextPage1('Cancel')}><b>Cancel</b></button>
                    <button id="continue" className="btn btn-outline-primary" onClick={() => redirectToNextPage1('Confirm')}><b>Confirm</b></button>
                    <button id="continue" className="btn btn-outline-primary" onClick={() => redirectToNextPage1('Review')}><b>Review</b></button>
                    <button id="continue" className="btn btn btn-outline-dark">Back</button>
                </div>
            </section>
            {
                (showSucessModal) && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>
                                    Receipt {successMessage}
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Receipt has been  {successMessage} successfully!
                                <br>
                                    </br>
                                    <br>
                                    </br>
                                </span><br />
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                {/* <button id="download-receipt" className="primary-button">Ok</button> */}
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (isLoading) && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fillRule="evenodd" strokeWidth="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
            }
        </div>

    )

}

export default Review2;
