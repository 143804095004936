import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PrintCheque.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LanguageVariant } from 'typescript';

const PrintCheque = () => {
    const goToPreviewList = () => {
        navigate('/PrintChequeReviewList');
    }
    interface searchDataProps {
        payee_name: string;
    }
    let formData: searchDataProps = {
        payee_name: ""
    };
    interface PayeeAccProps {
        PaymentRefNumber: string;
        AccountHolderName: string;
        AccountNumber: string;
        BankName: string;
        PayeeName: string;
        Branch: string;
        IBAN: string;
        CheckType: string;
        PayAmount: number;
        PayeeOldDocNumber: string;
        PayDate: string;
        PayRemarks: string;
    }
    interface PayoutProps {
        Type: string;
        Method: string;
        BankName: string;
        AccountNumber: string;
        BankCode: string;
        RCCode: string;
        Status: string;
        StatusDate: string;
        OldPVNumber: string;
    }
    interface voucherPrintProps {
        PaymentDate: string;
        PVNumber: string;
        PayeeName: string;
        PVAmount: string;
        PayoutDetails: PayoutProps;
        PaymentItem: string;
        isSelected?: boolean;
        Amount: number;
        PayeeAccountDetails: PayeeAccProps;
    }
    const calculateAmount = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ItemDetails.map((el: any) => {
            newData.Amount = newData.Amount + el.ItemAmount;
        });
        return newData;
    }
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
    });

    const handleUpdate = (e: any, isReceipt = true) => {
        const { name, value } = e.target;
        const newFormData = { ...searchData };
        // @ts-ignore
        newFormData[name] = value;
        setSearchData(newFormData);
    }
    const handleCheckBoxChange = (e: any, rNo: string) => {
        const { checked } = e.target;
        const newVoucherList = [...voucherList];
        const selectedVoucher: voucherPrintProps[] = [];
        newVoucherList.map(rl => {
            if (rl.PVNumber === rNo || rNo === 'ALL') {
                rl.isSelected = checked;
                selectedVoucher.push(rl);
            }
        });
        setVoucherList(newVoucherList);
    }

    const [searchData, setSearchData] = useState<searchDataProps>(formData);
    const [voucherList, setVoucherList] = useState<voucherPrintProps[]>([]);

    const searchVocher = () => {
        console.log ('rasika');
        debugger;
        const searchStr = `${searchData.payee_name}`;
        if (searchData.payee_name) {
            console.log( 'arvind');
            try {
                fetch(`https://group.sonic.dev.coherent.com.hk/finance/v1/paymentvoucher/PayeeName/${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        const vouherArr = response.map((voucher: voucherProps) => {
                            voucher.isSelected = false;
                            return calculateAmount(voucher);
                        })
                        setVoucherList(vouherArr);
                    });
            } catch (exception) {
                console.log(exception);
            }
        }
    }

    return (
        <div id="PrintCheque-container">
            <ReceiptHeader />
            <section>
                <div className="content-container" id="font">
                    <div className="content-title">
                        <p>Print Cheque</p>
                    </div>

                   <div className="content-box payee-details">
                        <p className="title">Search</p>
                        <div className="">

                            <div className="form-group">
                                <div className="col-sm-8 mb-1 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Bank - Branch</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-1 mb-1 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-1 mb-1 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                        </div>
                                        <div className="col-sm-8 mb-8 float-left remove-left-pad">
                                            <select className="form-control form-text" name="account_name" id="account_name">
                                                <option value="">--Select Account--</option>
                                                <option value="1">Option 1</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Payee Account Number</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <input type="text" className="form-control form-text" id="search_value" name="search_value" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-3 mb-3 float-left">
                                    <label>Payee Name</label>
                                    <input type="text" className="form-control form-text" id="payee_name" name="payee_name" onChange={(e) => handleUpdate (e) } />
                                </div>
                                <div className="col-sm-5 mb-5 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Bank Transfer Date</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-6 mb-6 float-left remove-left-pad">
                                            <input type="date" className="form-control form-text" id="from_date" name="from_date" />
                                        </div>
                                        <div className="col-sm-6 mb-6 float-left remove-left-pad">
                                            <input type="date" className="form-control form-text" id="to_date" name="to_date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-4 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Bank Transfer Type</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <div className="col-sm-4 mb-4 float-left remove-left-pad">
                                            <button className="filter-button secondary-button transtype-button active">Same Bank</button>
                                        </div>
                                        <div className="col-sm-4 mb-4 float-left remove-left-pad">
                                            <button className="filter-button secondary-button transtype-button">Different Bank</button>
                                        </div>
                                        <div className="col-sm-4 mb-4 float-left remove-left-pad">
                                            <button className="filter-button secondary-button transtype-button">Same Bank</button>
                                        </div>


                                        {/* <button className="col-sm-4 mb-4 filter-button secondary-button ml-1 mr-1">Different Bank</button>
                                        <button className="col-sm-4 mb-4 filter-button secondary-button ml-1">Same Bank</button> */}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-12 mb-12 float-left">
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <label>Credit Day Type</label>
                                    </div>
                                    <div className="col-sm-12 mb-12 remove-left-pad">
                                        <button className="col-sm-4 mb-4 filter-button secondary-button mr-1 active">Same Day</button>
                                        <button className="col-sm-4 mb-4 filter-button secondary-button ml-1 mr-1">Next Day</button>
                                        <button className="col-sm-4 mb-4 filter-button secondary-button ml-1">Both</button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-12 mb-12 float-left">
                                    <div className="col-md-1 mb-1 float-right btn-2-div">
                                        <button id="clear-filter" className="btn secondary-button btn-2">Clear</button>
                                    </div>
                                    <div className="col-md-1 mb-1 float-right btn-2-div">
                                        <button id="banktrans-search" className="primary-button" onClick={searchVocher}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                   
                   <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                        <div className="col-md-12 mb-1 title ">
                                <p>({voucherList.length}) Records Found</p>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input type="checkbox" name="checkAll" id="checkAll"
                                                    onChange={(e) => handleCheckBoxChange(e, 'ALL')} />
                                            </th>
                                            <th scope="col">Cheque #</th>
                                            <th scope="col">Bank-Branch</th>
                                            <th scope="col">Payment Voucher #</th>
                                            <th scope="col">Payee Name </th>
                                            <th scope="col">Name on cheque</th>
                                            <th scope="col">Pick Up Branch</th>
                                            <th scope="col">Cheque Date</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                            voucherList.map((voucher, index) => (
                                                <tr key={`vouher-list-${index}`}>
                                                   <td>
                                                        <input type="checkbox" checked={voucher.isSelected}
                                                            name={`check-${voucher.PVNumber}`} id={`check-${voucher.PVNumber}`}
                                                            onChange={(e) => handleCheckBoxChange(e, voucher.PVNumber)} />
                                                    </td>
                                                    <td scope="col" >{voucher.PayoutDetails.AccountNumber}</td>
                                                    <td scope="col" >{voucher.PayoutDetails.BankName}</td>
                                                    <td scope="col">{voucher.PVNumber}</td>
                                                    <td scope="col" >{voucher.PayeeName}</td>
                                                    <td scope="col">{voucher.PayeeAccountDetails.AccountHolderName}</td>
                                                    <td scope="col">{voucher.PayeeAccountDetails.IBAN}-{voucher.PayeeAccountDetails.Branch}</td>
                                                    <td scope="col">{voucher.PayeeAccountDetails.PayDate}</td>
                                                    <td scope="col">{formatter.format(voucher.Amount)}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    {<div className="bottom">
                        <div className="form-group">
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="form-group">
                                <div className="col-md-2 mb-2 float-right">
                                <button id="receipt-search" className="primary-button"  onClick= {goToPreviewList} >Continue</button>
                                </div>
                                <div className="col-md-2 mb-2 float-right">
                                <button id="clear-filter" className="btn btn-outline-dark">Cancel</button>
                                </div>
                        </div>
                        </div>
                    }
                </div>
            </section>
        </div>
    )

}

export default PrintCheque;