import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps, pfDataProps, generalListProps } from '../interfaces';
import { getReceiptData, getInitialData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateReceipt.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreatReceipt = () => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
    });

    let formData: receiptListProps = {
        DepositeBy: "",
        DepositeByNo: "",
        BankCode: "",
        BankName: "",
        BankBranchName: "",
        DepositeRefNo: "",
        DepositeRefType: "",
        DepositeDate: "",
        DepositeAmount: "",
        BankFee: "",
        DepositeType: "",
        Status: "",
        action: "show"
    };

    const date = new Date()
    const defaultdate = `${date.getFullYear()}-${(date.getMonth() < 9) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${(date.getDate() < 10) ? '0' + date.getDate() : date.getDate()}`;
    let headerFormData: hederDataProps = {
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        ReceiptDate: defaultdate,
        Remarks: ""
    }

    const [showAddNew, setShowAddNew] = useState<boolean>(false);
    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [receiptList, setReceiptList] = useState<receiptListProps[]>([]);
    const [headerData, setHeaderData] = useState<hederDataProps>(headerFormData);
    const [receiptData, setReceiptData] = useState<receiptListProps>(formData);
    const [startDate, setStartDate] = useState<string>("");
    const [receiptNumber, setReceiptNumber] = useState<string>("");
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);

    const [payeeTypes, setPayeeTypes] = useState<string[]>([]);
    const [depositBy, setDepositBy] = useState<string[]>([]);
    const [depositType, setDepositType] = useState<string[]>([]);
    const [depositRefTypes, setDepositRefTypes] = useState<string[]>([]);
    const [receiptStatusList, setReceiptStatusList] = useState<string[]>([]);
    const [bankCodeList, setBankCodeList] = useState<string[]>([]);
    const [bankInfoList, setBankInfoList] = useState<object[]>([]);
    const [branchCodeList, setBranchCodeList] = useState<object[]>([]);
    const [branchNameList, setBranchNameList] = useState<object[]>([]);

    useEffect(() => {
        //@ts-ignore
        const existingData = window.receiptData;
        if (existingData) {
            existingData.ReceiptDetail.map((data: receiptListProps) => data.action = 'show');
            setReceiptList(existingData.ReceiptDetail);
            const headerFormData = {
                ReceiptNumber: existingData.ReceiptNumber,
                Currency: existingData.Currency,
                PayeeType: existingData.PayeeType,
                PayeeCode: existingData.PayeeCode,
                PayeeName: existingData.PayeeName,
                ReceiptDate: existingData.ReceiptDate,
                ReceiptStatus: existingData.ReceiptStatus,
                TransactionRef: existingData.TransactionRef,
                Remarks: existingData.Remarks,
                TranDate: existingData.TranDate,
            };
            setHeaderData(headerFormData);
            if (existingData.ReceiptDate) {
                setStartDate(existingData.ReceiptDate);
            }
            setIsEditFlow(true);
        }

        const storedData = (localStorage.getItem('GlData'));
        if (storedData) {
            const glData = JSON.parse(storedData);
            const {
                glForPayeeTypes, glForDepositBy, glForDepositType,
                glForDepositRefTypes, glForReceiptStatusList, glForBankCode,
                glForBankInfoCode
            } = glData;
            setPayeeTypes(glForPayeeTypes);
            setDepositBy(glForDepositBy);
            setDepositType(glForDepositType);
            setDepositRefTypes(glForDepositRefTypes);
            setReceiptStatusList(glForReceiptStatusList);
            setBankCodeList(glForBankCode);
            setBankInfoList(glForBankInfoCode);
        } else {
            getRData();
        }
    }, []);

    const getRData = async () => {
        try {
            setIsLoading(true);
            const initData = getInitialData();
            await fetch(`${endPoint.getPFData}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'SecretKey': '762f5692-5a8b-4f8e-a09e-a27a277c0c56'
                },
                body: JSON.stringify(initData)
            })
                .then((response: any) => response.json())
                .then((response: any) => {
                    setIsLoading(false);
                    const glForPayeeTypes: string[] = [];
                    const glForDepositBy: string[] = [];
                    const glForDepositType: string[] = [];
                    const glForDepositRefTypes: string[] = [];
                    const glForReceiptStatusList: string[] = [];
                    response.data.Outputs["Lists.GeneralLists"].map((gl: any) => {
                        if (gl.PAYEE_TYPE) {
                            glForPayeeTypes.push(gl.PAYEE_TYPE);
                        };
                        if (gl.DEPOSIT_BY) {
                            glForDepositBy.push(gl.DEPOSIT_BY);
                        };
                        if (gl.DEPOSIT_TYPE) {
                            glForDepositType.push(gl.DEPOSIT_TYPE);
                        };
                        if (gl.DEPOSIT_REF_TYPE) {
                            glForDepositRefTypes.push(gl.DEPOSIT_REF_TYPE);
                        };
                        if (gl.STATUS) {
                            glForReceiptStatusList.push(gl.STATUS);
                        };
                    });
                    const glForBankCode: string[] = [];
                    const glForBankInfoCode: object[] = [];
                    response.data.Outputs["Lists.BankBranch"].map((glb: any) => {
                        if (glb.BANK_CODE && !glForBankCode.includes(glb.BANK_CODE)) {
                            glForBankCode.push(glb.BANK_CODE);
                        }
                        if (glb.BANK_CODE && glb.BRANCH_CODE && glb.BRANCH_NAME_T) {
                            glForBankInfoCode.push({
                                BankCode: glb.BANK_CODE,
                                BranchCode: glb.BRANCH_CODE,
                                BranchName: glb.BRANCH_NAME_T
                            });
                        }
                    });
                    const GlData = {
                        glForPayeeTypes,
                        glForDepositBy,
                        glForDepositType,
                        glForDepositRefTypes,
                        glForReceiptStatusList,
                        glForBankCode,
                        glForBankInfoCode
                    };
                    localStorage.setItem('GlData', JSON.stringify(GlData));
                    setPayeeTypes(glForPayeeTypes);
                    setDepositBy(glForDepositBy);
                    setDepositType(glForDepositType);
                    setDepositRefTypes(glForDepositRefTypes);
                    setReceiptStatusList(glForReceiptStatusList);
                    setBankCodeList(glForBankCode);
                    setBankInfoList(glForBankInfoCode);
                });
        } catch (exception) {
            console.log(exception);
            setIsLoading(false);
        }
    }

    const addNewReceipt = () => {
        const addNewStatus = !showAddNew;
        setShowAddNew(addNewStatus);
    }

    const handleUpdate = (e: any, isReceipt = true) => {
        const { name, value } = e.target;
        if (isReceipt) {
            const newFormData = { ...receiptData };
            if (name === 'BankCode') {
                const filteredBranch = bankInfoList.filter(bki => bki.BankCode === Number(value));
                setBranchCodeList(filteredBranch);
            }
            if (name === 'BankBranchName') {
                branchCodeList.forEach(branchCode => {
                    //@ts-ignore
                    if (branchCode.BranchCode == value) {
                        //@ts-ignore
                        newFormData['BankName'] = branchCode.BranchName;
                    }
                });
            }
            // @ts-ignore
            newFormData[name] = value;
            setReceiptData(newFormData);
        } else {
            const hData = { ...headerData };
            // @ts-ignore
            hData[name] = value;
            setHeaderData(hData);
        }
    }

    const handleDateChange = (date: Date) => {
        const hData = { ...headerData };
        // @ts-ignore
        hData["ReceiptDate"] = `${date.getFullYear()}-${(date.getMonth() < 9) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${(date.getDate() < 10) ? '0' + date.getDate() : date.getDate()}`;
        setStartDate(hData["ReceiptDate"]);
        setHeaderData(hData);
    }

    const savePolicy = () => {
        const pList = [...receiptList];
        pList.push(receiptData);
        setReceiptList(pList);
        setShowAddNew(false)
        setReceiptData(formData);
    }

    const submitReceipts = () => {
        try {
            if (headerData.PayeeType == "" || headerData.PayeeCode == "" || headerData.PayeeName == "" || headerData.ReceiptDate == "") {
                alert('Payee Type, Payee Code, Payee Name & Reciept date can not be blank');
                return;
            } else if (receiptList.length < 1) {
                alert('Please add at lease 1 receipt details');
                return;
            }
            const receiptData = getReceiptData(receiptList, headerData);
            fetch(`${endPoint.createReceipt}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(receiptData)
            })
                .then((response: any) => response.json())
                .then((response: any) => {
                    setShowSucessModal(true);
                    setReceiptNumber(response);
                });
            setShowSucessModal(true);
        } catch (exception) {
            console.log(exception);
            setShowSucessModal(true);
        }
    }

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }

    const copyReceiptNumber = () => {
        const text = receiptNumber;
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const enableEdit = (index: number, action: string) => {
        const upData = [...receiptList];
        // @ts-ignore
        upData[index].action = action;
        setReceiptList(upData);
    }

    const handleUpdateOnModify = (e: any, index: number) => {
        const { name, value } = e.target;
        const upData = [...receiptList];
        // @ts-ignore
        upData[index][name] = value;
        if (name === 'BankCode') {
            const filteredBranch = bankInfoList.filter(bki => bki.BankCode === Number(value));
            setBranchCodeList(filteredBranch);
        }
        branchCodeList.forEach(branchCode => {
            //@ts-ignore
            if (branchCode.BranchCode == value) {
                //@ts-ignore
                upData[index]['BankName'] = branchCode.BranchName;
            }
        });
        setReceiptList(upData);
    }

    const deleteItem = (index: number) => {
        if (index >= 0) {
            const upData = [...receiptList];
            upData.splice(index, 1);
            setReceiptList(upData);
        } else {
            addNewReceipt();
        }
    }

    return (
        <div id="create-receipt-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-title">
                        <p>{(!isEditFlow ? 'Deposit' : 'Modify')} Receipt</p>
                    </div>

                    <div className="content-box payee-details">
                        <p className="title">Payee Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label >Payee Type</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" onChange={(e) => handleUpdate(e, false)} value={headerData.PayeeType}>
                                        <option value="">---Select Type---</option>
                                        {
                                            payeeTypes.map((pt) => (
                                                <option value={pt}>{pt}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Payee Code</label>
                                    <input type="text" onChange={(e) => handleUpdate(e, false)} className="form-control form-text"
                                        id="PayeeCode" name="PayeeCode" placeholder="Enter payee code" value={headerData.PayeeCode} />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Payee Name</label>
                                    <input type="text" onChange={(e) => handleUpdate(e, false)} className="form-control form-text"
                                        id="PayeeName" name="PayeeName" placeholder="Enter payee name" value={headerData.PayeeName} />
                                </div>
                            </div>
                            <div className="form-group form-p">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Remark</label>
                                    <input type="text" onChange={(e) => handleUpdate(e, false)} className="form-control form-text"
                                        id="Remarks" name="Remarks" placeholder="Enter here" value={headerData.Remarks} />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Date</label><br />
                                    {/* <DatePicker className="form-control form-text" id="ReceiptDate" name="ReceiptDate" selected={startDate}
                                            placeholderText="YYYY-mm-dd" onChange={(date: Date) => handleDateChange(date)} dateFormat="yyyy-MM-dd"
                                            maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} />
                                        <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/cal.png" alt="Calendar" id="cal" /> */}
                                    {/* <DatePicker className="form-control form-text" id="ReceiptDate" name="ReceiptDate" selected={startDate}
                                            placeholderText="YYYY-mm-dd" onChange={(date: Date) => setStartDate(date)} /> */}
                                    <input type="date" onChange={(e) => handleUpdate(e, false)} value={headerData.ReceiptDate}
                                        className="form-control form-text" id="ReceiptDate" name="ReceiptDate"
                                        placeholder="YYYY-mm-dd" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                            <div className="col-md-12 mb-2 title ">
                                <p>Receipt Details</p>
                                <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-1 mb-2 float-left">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Deposit By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            receiptList.map((receipt, index) => (
                                                <React.Fragment>
                                                    {
                                                        (receipt.action === 'show') && (
                                                            <tr key={`${index}-show`} className="view-row">
                                                                <td>{receipt.DepositeBy}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        (receipt.action === 'edit') && (
                                                            <tr key={`${index}-edit`}>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeBy} name="DepositeBy" id="DepositeBy">
                                                                        <option value="">Choose Any</option>
                                                                        {
                                                                            depositBy.map((d) => (
                                                                                <option value={d}>{d}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </React.Fragment>

                                            ))
                                        }
                                        {
                                            showAddNew && (
                                                <tr>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)} value={receiptData.DepositeBy} name="DepositeBy" id="DepositeBy">
                                                            <option value="">Choose Any</option>
                                                            {
                                                                depositBy.map((d) => (
                                                                    <option value={d}>{d}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-10 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Deposit By #</th>
                                            <th scope="col">Bank Code</th>
                                            <th scope="col">Branch Code</th>
                                            <th scope="col">Branch Name</th>
                                            <th scope="col">Ref Type</th>
                                            <th scope="col">Ref #</th>
                                            <th scope="col">Deposit Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Fee</th>
                                            <th scope="col">Deposit Type</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            receiptList.map((receipt, index) => (
                                                <React.Fragment>
                                                    {
                                                        (receipt.action === 'show') && (
                                                            <tr key={`${index}-show`} className="view-row">
                                                                <td>{receipt.DepositeByNo || '-'}</td>
                                                                <td>{receipt.BankCode || '-'}</td>
                                                                <td>{receipt.BankBranchName || '-'}</td>
                                                                <td>{receipt.BankName || '-'}</td>
                                                                <td>{receipt.DepositeRefType || '-'}</td>
                                                                <td>{receipt.DepositeRefNo || '-'}</td>
                                                                <td>{receipt.DepositeDate || '-'}</td>
                                                                <td>{formatter.format(parseFloat(receipt.DepositeAmount)) || '-'}</td>
                                                                <td>{formatter.format(parseFloat(receipt.BankFee)) || '-'}</td>
                                                                <td>{receipt.DepositeType || '-'}</td>
                                                                <td>{receipt.Status || '-'}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        (receipt.action === 'edit') && (
                                                            <tr key={`${index}-edit`}>
                                                                <td>
                                                                    <input type="text" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeByNo}
                                                                        className="form-control form-text" id="DepositeByNo" name="DepositeByNo" placeholder="Deposit #" />
                                                                </td>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.BankCode} name="BankCode" id="BankCode">
                                                                        <option value="">Select</option>
                                                                        {
                                                                            bankCodeList.map((bc) => (
                                                                                <option value={bc}>{bc}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.BankBranchName} name="BankBranchName" id="BankBranchName" >
                                                                        <option value="">Select</option>
                                                                        {
                                                                            branchCodeList.map(br => (
                                                                                <option value={br.BranchCode}>{br.BranchCode}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control form-text" value={receipt.BankName} name="BankName" id="BankName" disabled={true} />
                                                                    {/* <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.BankName} name="BankName" id="BankName">
                                                                        <option value="">Select</option>
                                                                        {
                                                                            branchCodeList.map(br => (
                                                                                <option value={br.BranchName}>{br.BranchName}</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </td>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeRefType} name="DepositeRefType" id="DepositeRefType">
                                                                        <option value="">Select</option>
                                                                        {
                                                                            depositRefTypes.map((drt) => (
                                                                                <option value={drt}>{drt}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeRefNo}
                                                                        className="form-control form-text" id="DepositeRefNo" name="DepositeRefNo" placeholder="Ref #" />
                                                                </td>
                                                                <td>
                                                                    {/* <DatePicker className="form-control form-text" id="DepositeDate" name="DepositeDate" selected={startDate}
                                                                        placeholderText="YYYY-MM-dd" onChange={(date: Date) => handleDateChange(date)} dateFormat="yyyy-MM-dd"
                                                                        maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} />
                                                                    <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/cal.png" alt="Calendar" id="cal" /> */}
                                                                    <input type="date" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeDate}
                                                                        className="form-control form-text" id="DepositeDate" name="DepositeDate" placeholder="YYYY-MM-DD" />
                                                                </td>
                                                                <td>
                                                                    <input type="number" min="0" max="" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeAmount}
                                                                        className="form-control form-text" id="DepositeAmount" name="DepositAmount" placeholder="0.00" />
                                                                </td>
                                                                <td>
                                                                    <input type="number" min="0" max="" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.BankFee}
                                                                        className="form-control form-text" id="BankFee" name="BankFee" placeholder="0.00" />
                                                                </td>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.DepositeType} name="DepositeType" id="DepositeType">
                                                                        <option value="">Select Type</option>
                                                                        {
                                                                            depositType.map((dt) => (
                                                                                <option value={dt}>{dt}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select className="form-control" onChange={(e) => handleUpdateOnModify(e, index)} value={receipt.Status} name="Status" id="Status">
                                                                        <option value="">Select Status</option>
                                                                        {
                                                                            receiptStatusList.map((rec) => (
                                                                                <option value={rec}>{rec}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                        {
                                            showAddNew && (
                                                <tr>
                                                    <td>
                                                        <input type="text" onChange={(e) => handleUpdate(e, true)} className="form-control form-text"
                                                            value={receiptData.DepositeByNo} id="DepositeByNo" name="DepositeByNo" placeholder="Deposit #" />
                                                    </td>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)} value={receiptData.BankCode} name="BankCode" id="BankCode">
                                                            <option value="">Select</option>
                                                            {
                                                                bankCodeList.map((bc) => (
                                                                    <option value={bc}>{bc}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)} value={receiptData.BankBranchName} name="BankBranchName" id="BankBranchName">
                                                            <option value="">Select</option>
                                                            {
                                                                branchCodeList.map(br => (
                                                                    <option value={br.BranchCode}>{br.BranchCode}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control form-text" value={receiptData.BankName} name="BankName" id="BankName" disabled={true} />
                                                        {/* <select className="form-control" onChange={(e) => handleUpdate(e, true)} value={receiptData.BankName} name="BankName" id="BankName">
                                                            <option value="">Select</option>
                                                            {
                                                                branchCodeList.map(br => (
                                                                    <option value={br.BranchName}>{br.BranchName}</option>
                                                                ))
                                                            }
                                                        </select> */}
                                                    </td>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)} value={receiptData.DepositeRefType} name="DepositeRefType" id="DepositeRefType">
                                                            <option value="">Select</option>
                                                            {
                                                                depositRefTypes.map((drt) => (
                                                                    <option value={drt}>{drt}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" onChange={(e) => handleUpdate(e, true)} className="form-control form-text"
                                                            value={receiptData.DepositeRefNo} id="DepositeRefNo" name="DepositeRefNo" placeholder="Ref #" />
                                                    </td>
                                                    <td>
                                                        {/* <DatePicker className="form-control form-text" id="DepositeDate" name="DepositeDate"
                                                            placeholderText="YYYY-mm-dd" onChange={(date: Date) => handleDateChange(date)} dateFormat="yyyy-MM-dd"
                                                            maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} />
                                                        <img src="https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/cal.png" alt="Calendar" id="cal" /> */}
                                                        <input type="date" onChange={(e) => handleUpdate(e, true)} className="form-control form-text"
                                                            value={receiptData.DepositeDate} id="DepositeDate" name="DepositeDate" placeholder="YYYY/MM/DD" />
                                                        {/* <DatePicker className="form-control form-text" id="DepositeDate" name="DepositeDate" selected={startDate}
                                                            placeholderText="YYYY/mm/dd" onChange={(date: Date) => handleDateChange(date)} dateFormat="yyyy/MM/dd"
                                                            maxDate={startDate} showMonthDropdown={true} showYearDropdown={true} /> */}
                                                    </td>
                                                    <td>
                                                        <input type="number" min="0" max="" onChange={(e) => handleUpdate(e, true)} className="form-control form-text"
                                                            value={receiptData.DepositeAmount} id="DepositeAmount" name="DepositeAmount" placeholder="0.00" />
                                                    </td>
                                                    <td>
                                                        <input type="number" min="0" max="" onChange={(e) => handleUpdate(e, true)} className="form-control form-text"
                                                            value={receiptData.BankFee} id="BankFee" name="BankFee" placeholder="0.00" />
                                                    </td>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)}
                                                            value={receiptData.DepositeType} name="DepositeType" id="DepositeType">
                                                            <option value="">Select Type</option>
                                                            {
                                                                depositType.map((dt) => (
                                                                    <option value={dt}>{dt}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="form-control" onChange={(e) => handleUpdate(e, true)}
                                                            value={receiptData.Status} name="Status" id="Status">
                                                            <option value="">Select Status</option>
                                                            {
                                                                receiptStatusList.map((rec) => (
                                                                    <option value={rec}>{rec}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-1 mb-2 float-right">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td> */}
                                        {/* <span className="glyphicon">&#x270f;</span>
                                                <span className="glyphicon">&#xe014;</span> */}
                                        {/* <a href="javascript:void(0)">Edit</a>&nbsp;&nbsp;&nbsp;
                                                <a href="javascript:void(0)">Delete</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>@mdo</td>
                                        </tr> */}
                                        {
                                            receiptList.map((receipt, index) => (
                                                <tr key={index} className="action-row">
                                                    <td>

                                                        {
                                                            (receipt.action === 'show') && (
                                                                <span className="anchor-links" onClick={() => enableEdit(index, 'edit')}>
                                                                    <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                </span>
                                                            )
                                                        }
                                                        {
                                                            (receipt.action === 'edit') && (
                                                                <span className="anchor-links" onClick={() => enableEdit(index, 'show')}>
                                                                    <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                                </span>
                                                            )
                                                        }
                                                        <span className="anchor-links" onClick={() => deleteItem(index)}>
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            showAddNew && (
                                                <tr className="action-row">
                                                    <td>
                                                        <span className="anchor-links" onClick={savePolicy}>
                                                            <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                        </span>&nbsp;&nbsp;&nbsp;
                                                        <span className="anchor-links" onClick={() => deleteItem(-1)}>
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="receipt-table-header">
                            <div className="col-md-12 mb-2 title ">
                                <button onClick={addNewReceipt} className="btn btn-outline-dark">+Add New</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <button id="continue" className="button-common primary-button" onClick={submitReceipts}>
                        {
                            (!isEditFlow) ? 'Create & Submit for Approval' : 'Update Changes'
                        }
                    </button>
                    {
                        (!isEditFlow) && (
                            <button id="SaveAsDraft" className="button-common btn btn-outline-primary"><b>Save as Draft</b></button>
                        )
                    }
                    <button id="CreateRCancel" className="button-common btn btn-outline-dark">Cancel</button>
                </div>
            </section>
            {
                showSucessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>
                                    {
                                        (!isEditFlow) ? 'Receipt Created' : 'Changes Updated'
                                    }
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    {
                                        (!isEditFlow) ? 'Receipt Number' : 'Changes has been updated successfully in Journal Voucher Number'
                                    }
                                </span><br />
                                <span className="receipt-number">{receiptNumber}</span> <span className="receipt-copy" onClick={copyReceiptNumber}>Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt" className="primary-button">Download Receipt</button>
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isLoading && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fillRule="evenodd" strokeWidth="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CreatReceipt;
