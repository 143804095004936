import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BankTransferPreviewList.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const BankTransfer2 = () => {

    const goToPreviewList = () => {
        navigate('/BankTransfer');
    }

    
    const goToReportGenerate = () => {
        setShowSucessModal(true);
    }

    // 1 - showSucessModal
    // 2 - setShowSucessModal
    
    
    const rList: any = [];

    let formData: receiptListProps = {
        DepositeBy: "",
        DepositeByNo: "",
        BankCode: "",
        BankName: "",
        BankBranch: "",
        DepositeRefNo: "",
        DepositeRefType: "",
        DepositeDate: "",
        DepositeAmount: "",
        BankFee: "",
        DepositeType: "",
        status: "",
        action: "show"
    };

    let headerFormData: hederDataProps = {
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        ReceiptDate: "",
        Remarks: ""
    }

    // const [{ policyId, data, fetching }] = useGlobalState('PolicyReducer');
    const [showAddNew, setShowAddNew] = useState<boolean>(false);
    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [receiptList, setReceiptList] = useState<receiptListProps[]>(rList);
    const [headerData, setHeaderData] = useState<hederDataProps>(headerFormData);
    const [startDate, setStartDate] = useState(new Date());
    const [receiptNumber, setReceiptNumber] = useState<string>("");

    useEffect(() => {
        //@ts-ignore
        const existingData = window.receiptData;
        if (existingData) {
            //console.log('this is window =======', window.receiptData);
            existingData.ReceiptDetail.map((data: receiptListProps) => data.action = 'show');
            console.log(existingData);
            setReceiptList(existingData.ReceiptDetail);
            const headerFormData = {
                ReceiptNumber: existingData.ReceiptNumber,
                Currency: existingData.Currency,
                PayeeType: existingData.PayeeType,
                PayeeCode: existingData.PayeeCode,
                //PayeeName: existingData.PayeeName,
                ReceiptDate: existingData.ReceiptDate,
                ReceiptStatus: existingData.ReceiptStatus,
                TransactionRef: existingData.TransactionRef,
                Remarks: existingData.Remarks,
                TranDate: existingData.TranDate,
            };
            setHeaderData(headerFormData);
            if (existingData.ReceiptDate) {
                const rDate = new Date(existingData.ReceiptDate);
                setStartDate(rDate);
            }
        }
    }, []);

    const addNewReceipt = () => {
        const addNewStatus = !showAddNew;
        setShowAddNew(addNewStatus);
    }

    const handleUpdate = (e: any, isReceipt = true) => {
        const { name, value } = e.target;
        if (isReceipt) {
            // @ts-ignore
            formData[name] = value;
        } else {
            const hData = { ...headerData };
            // @ts-ignore
            hData[name] = value;
            setHeaderData(hData);
        }
    }

    const handleDateChange = (date: Date) => {
        const hData = { ...headerData };
        // @ts-ignore
        hData["ReceiptDate"] = `${date.getFullYear()}-${(date.getMonth() < 9) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${(date.getDate() < 10) ? '0' + date.getDate() : date.getDate()}`;
        setStartDate(date);
        setHeaderData(hData);
    }

    const savePolicy = () => {
        const pList = receiptList;
        pList.push(formData);
        setReceiptList(pList);
        setShowAddNew(false)
    }

    const submitReceipts = () => {
        try {
            debugger;
            const receiptData = getReceiptData(receiptList, headerData);
            console.log(receiptData);
            fetch(`https://general.sonic.dev.coherent.com.hk/general/v2/finance/receipt`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(receiptData)
            })
                .then((response: any) => response.json())
                .then((response: any) => {
                    setShowSucessModal(true);
                    setReceiptNumber(response);
                    console.log('this is response', response);
                });
            setShowSucessModal(true);
        } catch (exception) {
            console.log(exception);
            setShowSucessModal(true);
        }
    }

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }

   
    return (
        <div id="bank-transfer-container2">
            <ReceiptHeader />
            <section>
                <div className="content-container" id="font">
                    <div className="content-title">
                        <p>Bank Transfer/Preview</p>
                    </div>

                    <div className="content-box banktrans-details">
                        <div className="banktrans-table-header">
                            <div className="col-md-12 mb-1 title">
                                <p>0 Record(s) Found</p>
                            </div>
                        </div>
                        <div className="banktrans-table-container">
                            <div className="col-md-12 mb-2 banktrans-table-c">
                                <table className="table table-striped banktrans-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pad-left">Payer Account#</th>
                                            <th scope="col">Payment Voucher # </th>
                                            <th scope="col">Payee Name</th>
                                            <th scope="col">Bank Tramsfer Date</th>
                                            <th scope="col">Bank Transfer Type</th>
                                            <th scope="col">Credit Day</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="pad-left">Test 1</td>
                                            <td>Test 2</td>
                                            <td>Test 3</td>
                                            <td>Test 4</td>
                                            <td>Test 5</td>
                                            <td>Test 6</td>
                                            <td>Test 7</td>
                                        </tr>
                                        <tr>
                                            <td className="pad-left">Test 1</td>
                                            <td>Test 2</td>
                                            <td>Test 3</td>
                                            <td>Test 4</td>
                                            <td>Test 5</td>
                                            <td>Test 6</td>
                                            <td>Test 7</td>
                                        </tr>
                                        <tr>
                                            <td className="pad-left">Test 1</td>
                                            <td>Test 2</td>
                                            <td>Test 3</td>
                                            <td>Test 4</td>
                                            <td>Test 5</td>
                                            <td>Test 6</td>
                                            <td>Test 7</td>
                                        </tr>
                                    </tbody>
                                    {/*
                                            tReceiptsList.ReceiptNumber !== "" && (
                                                <tr>
                                                    <td className="receipt-number" onClick={goToReceiptDetail}><input type="checkbox" id="check1"></input>&nbsp;{tReceiptsList.ReceiptNumber}</td>
                                                    <td>{tReceiptsList.Amount}</td>
                                                    <td>{tReceiptsList.PayeeCode}</td>
                                                    <td>{tReceiptsList.PayeeName}</td>
                                                    <td>{tReceiptsList.ReceiptDate}</td>
                                                    <td>{tReceiptsList.ReceiptStatus}</td>
                                                </tr>
                                            )
                                        }
                                        {/* {
                                            tReceiptsList.ReceiptNumber !== "" && receiptsList.map((receipt, index) => (
                                                <tr>
                                                    <td><input type="checkbox" id="check1"></input>&nbsp;{receipt.ReceiptNumber}</td>
                                                    <td>{receipt.ReceiptDetail[0].DepositeAmount}</td>
                                                    <td>{receipt.PayeeCode}</td>
                                                    <td>{receipt.PayeeName}</td>
                                                    <td>{receipt.ReceiptDate}</td>
                                                    <td>{receipt.ReceiptStatus}</td>
                                                </tr>
                                            ))

                                        } */}
                                </table>
                            </div>
                        </div>
                    </div>  
                </div>
               
                <div className="bottom">
                    <button id="continue" onClick={goToReportGenerate}>Confirm & Generate Report</button>
                    <button id="CreateRCancel" className="button-common btn btn-outline-dark" onClick={goToPreviewList}>Back</button>
                </div>
            </section>

            {
                showSucessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>Premium Applied</span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span className="success-message-container">Report has been created succesfully!</span> <br /><br />
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                              
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            
            {
                isLoading && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fill-rule="evenodd" stroke-width="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default BankTransfer2;
