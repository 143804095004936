import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PrintCheque2.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LanguageVariant } from 'typescript';


const PrintCheque2 = () => {
    const goToPrintChequeFirstPage = () => {
        navigate('/PrintCheque');
    }
    return (
        <div id="PrintCheque2-container">
            <ReceiptHeader />
            <section>
            <div className="content-container" id="font">
            <div className="content-title">
                        <p>Print Cheque/Preview</p>
            </div>
                    <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                        <div className="col-md-12 mb-1 title ">
                                <p>(2)Selected</p>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cheque #</th>
                                            <th scope="col">Bank-Branch</th>
                                            <th scope="col">Payment Voucher #</th>
                                            <th scope="col">Payee Name </th>
                                            <th scope="col">Name on cheque</th>
                                            <th scope="col">Pick Up Branch</th>
                                            <th scope="col">Cheque Date</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/*
                                            tReceiptsList.ReceiptNumber !== "" && (
                                                <tr>
                                                    <td className="receipt-number" onClick={goToReceiptDetail}><input type="checkbox" id="check1"></input>&nbsp;{tReceiptsList.ReceiptNumber}</td>
                                                    <td>{tReceiptsList.Amount}</td>
                                                    <td>{tReceiptsList.PayeeCode}</td>
                                                    <td>{tReceiptsList.PayeeName}</td>
                                                    <td>{tReceiptsList.ReceiptDate}</td>
                                                    <td>{tReceiptsList.ReceiptStatus}</td>
                                                </tr>
                                            )
                                        }
                                        {/* {
                                            tReceiptsList.ReceiptNumber !== "" && receiptsList.map((receipt, index) => (
                                                <tr>
                                                    <td><input type="checkbox" id="check1"></input>&nbsp;{receipt.ReceiptNumber}</td>
                                                    <td>{receipt.ReceiptDetail[0].DepositeAmount}</td>
                                                    <td>{receipt.PayeeCode}</td>
                                                    <td>{receipt.PayeeName}</td>
                                                    <td>{receipt.ReceiptDate}</td>
                                                    <td>{receipt.ReceiptStatus}</td>
                                                </tr>
                                            ))

                                        } */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    {<div className="bottom">
                        <div className="form-group">
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div className="form-group">
                                <div className="col-md-2 mb-2 float-right">
                                <button id="receipt-search" className="primary-button" onClick={() => window.print()}>Continues Print</button>
                                </div>
                                <div className="col-md-2 mb-2 float-right">
                                <button id="clear-filter" className="btn btn-outline-dark" onClick={() => window.print()}>Cutsheet Print</button>
                                </div>
                                <div className="col-md-2 mb-2 float-right">
                                <button id="backPrint" className="btn btn-outline-dark" onClick = {goToPrintChequeFirstPage}>Back</button>
                                </div>
                        </div>
                        </div>
                    }
            
                </div>
            </section>
        </div>
    )

}

export default PrintCheque2;