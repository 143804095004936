import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Cheque2.css';

const ChequeManage2 = () => {

    const goToUpdateStatusRePrint = () => {
        navigate('/Cheque3');
    }
    const goToChequeFirstPage = () => {
        navigate('/Cheque');
    }

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }
    interface PayoutProps {
        Type: string;
        Method: string;
        BankName: string;
        AccountNumber: string;
        BankCode: string;
        RCCode: string;
        Status: string;
        StatusDate: string;
        OldPVNumber: string;
    }

    interface PayeeAccProps {
        PaymentRefNumber: string;
        AccountHolderName: string;
        AccountNumber: string;
        BankName: string;
        PayeeName: string;
        Branch: string;
        IBAN: string;
        CheckType: string;
        PayAmount: number;
        PayeeOldDocNumber: string;
        PayDate: string;
        PayRemarks: string;
    }

    interface voucherProps {
        PVNumber: string;
        PaymentDate: string;
        PayeeName: string;
        PVAmount: string;
        PayoutDetails: PayoutProps;
        PayeeAccountDetails: PayeeAccProps;
        isSelected?: boolean;
    }

    const voucher: voucherProps = {
        PVNumber: '',
        PaymentDate: '',
        PayeeName: '',
        PVAmount: '',
        PayoutDetails: {
            Type: "",
            Method: "",
            BankName: "",
            AccountNumber: "",
            BankCode: "",
            RCCode: "",
            Status: "",
            StatusDate: "",
            OldPVNumber: ""
        },
        PayeeAccountDetails: {
            PaymentRefNumber: "",
            AccountHolderName: "",
            AccountNumber: "",
            BankName: "",
            PayeeName: "",
            Branch: "",
            IBAN: "",
            CheckType: "",
            PayAmount: 0,
            PayeeOldDocNumber: "",
            PayDate: "",
            PayRemarks: ""
        }
    }

    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);
    const [voucherDetail, setVoucherDetail] = useState<voucherProps>(voucher);

    useEffect(() => {
        //@ts-ignore
        const voucherData = window.ChequeVoucherSelected;
        if (voucherData) {
            setVoucherDetail(voucherData);
        }
    }, [])

    const handleUpdate = (e: any, infoType: string) => {
        const { name, value } = e.target;
        const newVoucher = { ...voucherDetail };

        if (infoType === 'payout') {
            //@ts-ignore
            newVoucher.PayoutDetails[name] = value;
        } else {
            //@ts-ignore
            newVoucher.PayeeAccountDetails[name] = value;
        }
        setVoucherDetail(newVoucher);
    }

    const updateVoucher = () => {
        fetch(`${endPoint.createVoucher}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(voucherDetail)
        })
            .then((response: any) => response.json())
            .then((response: any) => {
                console.log(response);
                setShowSucessModal(true);
            });
    }

    console.log('this is render', voucherDetail);

    return (
        <div id="cheque-container2">
            <ReceiptHeader />
            <section>
                <div className="content-container" id="font">
                    <div className="content-title">
                        <p>Cheque Management</p>
                    </div>
                    <p>Cheque Management/<b>{voucherDetail.PVNumber}</b></p>
                    <div className="content-box payee-details">
                        <p className="title">Details</p>
                        <div className="form-group">
                            <div className="col-md-4 mb-1 float-left">
                                <label>Cheque Owner Name</label>
                                <input type="text" className="form-control form-text" id="AccountHolderName"
                                    onChange={(e) => handleUpdate(e, 'payout')} name="AccountHolderName"
                                    value={voucherDetail.PayeeAccountDetails.AccountHolderName} placeholder="Enter Here" />
                            </div>
                            <div className="col-md-4 mb-1 float-left">
                                <label>Pay in Date</label>
                                <input type="date" className="form-control form-text" id="PayInDate" name="PayInDate"
                                    onChange={(e) => handleUpdate(e, 'payout')} value={voucherDetail.PayoutDetails.StatusDate} placeholder="DD/MM/YYYY" />
                            </div>
                            <div className="col-md-4 mb-1 float-left">
                                <div className="col-md-10 mb-1 float-left">
                                    <label>Fee</label>
                                    <input type="text" className="form-control form-text" id="PayoutFee" name="PayoutFee"
                                        onChange={(e) => handleUpdate(e, 'payout')} />
                                </div>
                                <div className="col-md-2 mb-1 float-left or-container">
                                    <p className="">Baht</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-md-4 mb-1 float-left">
                                <label>Cheque Status</label>
                                <select className="form-control" name="Status" id="Status" value={voucherDetail.PayoutDetails.Status}
                                    onChange={(e) => handleUpdate(e, 'payout')} >
                                    <option value="">Select any</option>
                                    <option value="In Clearing">In Clearing</option>
                                    <option value="Picked Up">Picked Up</option>
                                    <option value="Cheque Bounced">Cheque Bounced</option>
                                    <option value="Cleared">Cleared</option>
                                    <option value="Cheque Return">Cheque Return</option>
                                </select>
                            </div>
                            <div className="col-md-4 mb-1 float-left">
                                <label>Status Date</label>
                                <input type="date" className="form-control form-text" id="StatusDate" name="StatusDate"
                                    onChange={(e) => handleUpdate(e, 'payout')} value={voucherDetail.PayoutDetails.StatusDate} placeholder="DD/MM/YYYY" />
                            </div>
                            <div className="col-md-4 mb-1 float-left">
                                <label>Reason</label>
                                <select className="form-control" name="search_by" id="search_by" onChange={(e) => handleUpdate(e, 'payout')}>
                                    <option value="Not Sufficient Balance in Bank">Not Sufficient Balance in Bank</option>
                                    <option value="Option 1">Option 1</option>
                                    <option value="Option 2">Option 2</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="content-box pay-cheque-details">
                        <p className="title">Pay Cheque Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Amount</label>
                                    <input type="text" className="form-control form-text" id="PayAmount" name="PayAmount"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.PayAmount} placeholder="0.00" />
                                </div>

                                <div className="col-md-4 mb-2 float-left">
                                    <label> Type of Pay Cheque*</label>
                                    <input type="text" className="form-control form-text" id="CheckType" name="CheckType"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.CheckType} placeholder="Enter here" />
                                </div>

                                <div className="col-md-4 mb-2 float-left">
                                    <div className="col-md-12 mb-2 float-left">
                                        <label>Cheque Number</label>
                                    </div>
                                    <div className="col-md-12 mb-2 float-left">
                                        <div className="col-md-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="AccountNumber" name="AccountNumber"
                                                onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.AccountNumber} placeholder="Enter here" />
                                        </div>
                                        <div className="col-md-10 mb-2 float-left">
                                            <input type="text" className="form-control form-text" id="BankName" name="BankName"
                                                onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.BankName} placeholder="Enter here" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Payee Name</label>
                                    <input type="text" className="form-control form-text" id="PayeeName" name="PayeeName"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.PayeeName} placeholder="Enter here" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Name on Cheque</label>
                                    <input type="text" className="form-control form-text" id="AccountHolderName" name="AccountHolderName"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.AccountHolderName} placeholder="Enter here" />
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Old Document Number</label>
                                    <input type="text" className="form-control form-text" id="PayeeOldDocNumber" name="PayeeOldDocNumber"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.PayeeOldDocNumber} placeholder="Enter Here..." />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Cheque Date</label>
                                    <input type="date" className="form-control form-text" id="PayDate" name="PayDate"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.PayDate} placeholder="Enter here" />
                                </div>

                                <div className="col-sm-4 mb-2 float-left">
                                    <div className="col-sm-12 mb-2 float-left">
                                        <label >Pickup Cheque at Branch</label>
                                    </div>
                                    <div className="col-sm-12 mb-2 float-left">
                                        <div className="col-sm-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text" id="IBAN" name="IBAN"
                                                onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.IBAN} />
                                        </div>
                                        <div className="col-sm-10 mb-2 float-left">
                                            <input type="text" className="form-control form-text" id="Branch" name="Branch"
                                                onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.Branch} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-2 float-left">
                                    <label>Remark</label>
                                    <input type="text" className="form-control form-text" id="PayRemarks" name="PayRemarks"
                                        onChange={(e) => handleUpdate(e, 'acc')} value={voucherDetail.PayeeAccountDetails.PayRemarks} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <button id="receipt-search" className="button-common primary-button" onClick={updateVoucher}>Update</button>

                    <button id="clear-filter" className="button-common btn btn-outline-dark" onClick={goToChequeFirstPage} >Cancel</button>
                </div>
            </section>
            {
                showSucessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>Changes Updated</span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span className="success-message-container">The chagnes have been updated successfully !</span> <br /><br />
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )

}

export default ChequeManage2;