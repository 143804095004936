import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ApplyPremiumfinal.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Preminumpage1 = () => {

    interface receiptLineItemsProp {
        DepositeAmount: number;
    }
    interface receiptProps {
        ReceiptNumber: string;
        ReceiptDetail: receiptLineItemsProp[];
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        ReceiptDate: string;
        ReceiptStatus: string;
        Amount: number;
        isSelected: boolean;
    }

    let tReceiptsList1: receiptProps = {
        ReceiptNumber: "",
        ReceiptDetail: [],
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        ReceiptDate: "",
        ReceiptStatus: "",
        Amount: 0,
        isSelected: false
    };

    interface searchDataProps {
        search_value: string;
        search_by: string;
        search_from_date: string;
        search_to_date: string;
    }

    let formData: searchDataProps = {
        search_value: "",
        search_by: "",
        search_from_date: "",
        search_to_date: ""
    };

    const [searchData, setSearchData] = useState<searchDataProps>(formData);
    const [receiptsList, setReceiptsList] = useState<any[]>([]);
    const [tReceiptsList, setTReceiptsList] = useState<receiptProps[]>([]);
    const [showReceiptTable, setShowReceiptTable] = useState<boolean>(false);

    const goToReceiptDetail = () => {
        navigate('/ApplyPremiumReceipts');
    };

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        formData[name] = value;
        const newFormData = { ...searchData };
        // @ts-ignore
        newFormData[name] = value;
        setSearchData(newFormData);
    }

    const handleCheckBoxChange = (e: any, rNo: string) => {
        const { checked, name, id } = e.target;
        const newRList = [...tReceiptsList];
        const premiumAppliedReceipts: receiptProps[] = [];
        newRList.map(rl => {
            if (rl.isSelected) {
                premiumAppliedReceipts.push(rl);
            }
            if (rl.ReceiptNumber === rNo || rNo === 'ALL') {
                rl.isSelected = checked;
                premiumAppliedReceipts.push(rl);
            }
        });
        // @ts-ignore
        window.APselectedReceiptData = premiumAppliedReceipts;
        setTReceiptsList(newRList);
    }

    const calculateAmount = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ReceiptDetail.map((el: any) => {
            newData.Amount = newData.Amount + el.DepositeAmount;
        });
        return newData;
    }

    const searchReceipt = () => {
        // if (!searchData.search_value) {
        //     searchData.search_value = 'R2342324';
        // }
        // if (!searchData.search_by) {
        //     searchData.search_by = 'PayeeCode';
        // }
        const searchStr = `${(searchData.search_by === 'Receipt_Number') ? '' : searchData.search_by + '/'}${searchData.search_value}`;
        if (searchData.search_value) {
            try {
                fetch(`${endPoint.getReceipt}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        console.log('this is the response form the API', response);
                        if (response && (response.ReceiptNumber || response.length)) {
                            const receiptList = ((searchData.search_by === 'Receipt_Number') ? [response] : response).map((rec: receiptProps) => {
                                rec.isSelected = false;
                                return calculateAmount(rec);
                            });
                            setTReceiptsList(receiptList);
                        }
                    });
            } catch (exception) {
                console.log(exception);
            }
        } else {
            console.log('Search value is empty');
        }
    }

    const clearFilter = () => {
        formData = {
            search_value: "",
            search_by: "",
            search_from_date: "",
            search_to_date: ""
        };
        setSearchData(formData);
        setTReceiptsList([]);
    }

    return (
        <div id="apply-preminum-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-title">
                        <p>Apply premium</p>
                    </div>
                    <div className="content-box payee-details">
                        <p className="title">Search Receipts</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Search Receipt By</label>
                                    <input type="text" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_value" name="search_value"
                                        placeholder="Begin your search" value={searchData.search_value} />
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label >&nbsp;</label>
                                    <select className="form-control" name="search_by" id="search_by" onChange={(e) => handleUpdate(e)} value={searchData.search_by}>
                                        <option value="">Search By</option>
                                        <option value="Receipt_Number">Receipt Number</option>
                                        <option value="PayeeCode">Payee Code</option>
                                    </select>
                                </div>
                                <div className="float-left or-container">
                                    <p className="">Or</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Date Range</label>
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_from_date" name="search_from_date" placeholder="YYYY/MM/DD" value={searchData.search_from_date} />
                                </div>
                                <div className="float-left or-container">
                                    <p className="">To</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left search-to">
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_to_date" name="search_to_date" placeholder="YYYY/MM/DD" value={searchData.search_to_date}/>
                                </div>
                                <div className="col-md-3 mb-2 float-left search-to">
                                    <button id="receipt-search" className="primary-button" onClick={searchReceipt}>Search</button>
                                    <button id="clear-filter" className="btn btn-outline-dark" onClick={clearFilter}>Clear filter</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        (tReceiptsList.length > 0) && (
                            <div className="content-box receipt-details">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <p>{tReceiptsList.length} Record(s) Found</p>
                                    </div>
                                </div>
                                <div className="receipt-table-container">
                                    <div className="col-md-12 mb-2 float-left middle-table">
                                        <table className="table table-striped receipt-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <input type="checkbox" name="checkAll" id="checkAll"
                                                            onChange={(e) => handleCheckBoxChange(e, 'ALL')} />
                                                    </th>
                                                    <th scope="col">Receipt #</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Payee Type</th>
                                                    <th scope="col">Payee Code</th>
                                                    <th scope="col">Payee Name</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Receipt Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tReceiptsList.map(rec => (
                                                        <tr key={`receipt-container-${rec.ReceiptNumber}`}>
                                                            <td>
                                                                <input type="checkbox" checked={rec.isSelected}
                                                                    name={`check-${rec.ReceiptNumber}`} id={`check-${rec.ReceiptNumber}`}
                                                                    onChange={(e) => handleCheckBoxChange(e, rec.ReceiptNumber)} />
                                                            </td>
                                                            <td>{rec.ReceiptNumber}</td>
                                                            <td>{rec.Amount}</td>
                                                            <td>{rec.PayeeType}</td>
                                                            <td>{rec.PayeeCode}</td>
                                                            <td>{rec.PayeeName}</td>
                                                            <td>{rec.ReceiptDate}</td>
                                                            <td>{rec.ReceiptStatus}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="bottom">
                    <button id="continue" onClick={goToReceiptDetail}>Next</button>
                </div>
            </section>

            {/* <div className="popup-box popup-msg">
                <div className="row success-msg success-msg-desktop">
                    <div className="background-success display-contents">
                        <span className="glyphicon glyphicon-ok-circle success-icon"><i className="bi bi-check-circle"></i></span>
                        <span className="message"> Please select only one payee code at a time </span>
                        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                <div className="dismiss-box">
                            <span className="payment-timestamp">12:06 15/04/2021</span>
                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                    <span className="dismiss">Dismiss </span>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Preminumpage1;
