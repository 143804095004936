import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getVoucherCreateData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateReceiptVoucher.css';


const CreateReceiptVoucher = () => {

    interface payeeDetailsProps {
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        PaymentItem: string;
        ItemRef: string;
        CostProfitCenter: string;
        PaymentDate: string;
        DueDate: string;
        Remarks: string;
        TotalAmount: number;
        TotalVAT: number;
        TotalWTH: number;
    }
    let payeeFormDetails: payeeDetailsProps = {
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        PaymentItem: "",
        ItemRef: "",
        CostProfitCenter: "",
        PaymentDate: "",
        DueDate: "",
        Remarks: "",
        TotalAmount: 0,
        TotalVAT: 0,
        TotalWTH: 0
    }
    interface itemDetailsProps {
        ItemNo: string;
        ItemNo2: string;
        ItemAmount: string;
        ItemAmount2: string;
        InsuredName: string;
        PolicyNo: string;
        TaxEximpt: string;
        VAT: number;
        WTH: number;
        Description: string;
        ItemDate: string;
        ItemDate2: string;
        ItemType: ""

    }
    let itemDetailsProps: itemDetailsProps = {
        ItemNo: "",
        ItemNo2: "",
        ItemAmount: "",
        ItemAmount2: "",
        InsuredName: "",
        PolicyNo: "",
        TaxEximpt: "",
        VAT: 0,
        WTH: 0,
        Description: "",
        ItemDate: "",
        ItemDate2: "",
        ItemType: ""
    }

    const [showCommissionTable, setShowCommissionTable] = useState<boolean>(false);
    const [showClaimTable, setShowClaimTable] = useState<boolean>(false);
    const [showRefundTable, setShowRefundTable] = useState<boolean>(false);
    const [showWTHTable, setShowWTHTable] = useState<boolean>(false);
    const [showAddNew, setShowAddNew] = useState<boolean>(false);
    const [payeeDetails, setPayeeDetails] = useState<payeeDetailsProps>(payeeFormDetails);
    const [lineItem, setLineItem] = useState<itemDetailsProps>(itemDetailsProps);
    const [lineItemsDetails, setLineItemsDetails] = useState<itemDetailsProps[]>([]);

    const addNewVoucher = () => {
        const addNewStatus = !showAddNew;
        setShowAddNew(addNewStatus);
    }

    // const showTestEdit = () => {
    //     const varTest = !testEdit;
    //     setTestEdit(varTest);
    //     const addNewStatus = !showAddNew;
    //     setShowAddNew(addNewStatus);
    // }

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        console.log('name & value', name, value);

        setShowCommissionTable(false);
        setShowClaimTable(false);
        setShowRefundTable(false);
        setShowWTHTable(false);

        if (value === 'commission') {
            setShowCommissionTable(true);
        } else if (value === 'claim') {
            setShowClaimTable(true);
        } else if (value === 'refund') {
            setShowRefundTable(true);
        } else if (value === 'wth') {
            setShowWTHTable(true);
        }

        const pData = { ...payeeDetails };
        // @ts-ignore
        pData[name] = value;
        setPayeeDetails(pData);
    }

    const handleItemsChange = (e: any) => {
        const { name, value } = e.target;
        const lineData = { ...lineItem };
        // @ts-ignore
        lineData[name] = value;
        setLineItem(lineData);
    }

    const addVoucherLineItem = () => {
        const voucherList = [...lineItemsDetails];
        voucherList.push(lineItem);
        setLineItemsDetails(voucherList);

        const payee = {...payeeDetails};
        payee.TotalAmount += Number(lineItem.ItemAmount);
        payee.TotalVAT += Number(lineItem.VAT);
        payee.TotalAmount += Number(lineItem.VAT);
        setPayeeDetails(payee);
        
        setLineItem(itemDetailsProps);
        addNewVoucher();
    }

    const removeVoucherLineItem = (index: number) => {
        const voucherList = [...lineItemsDetails];
        voucherList.splice(index, 1);
        setLineItemsDetails(voucherList);
    }

    const enableEditLineItem = (index: number) => {
        console.log('Need to edit this', index);
    }

    const submitVoucher = () => {
        const voucherData = getVoucherCreateData(payeeDetails, lineItemsDetails);
        fetch(`${endPoint.createVoucher}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(voucherData)
        })
            .then((response: any) => response.json())
            .then((response: any) => {
                console.log(response);
            });
    }

    const goToPayeeDetails = () => {
        //@ts-ignore
        window.CreateVoucherPayeeDetails = payeeDetails;
        //@ts-ignore
        window.CreateVoucherLineItems = lineItemsDetails;
        navigate('/CreatePaymentVoucher2');
    }

    console.log('this is render', payeeDetails);
    return (
        <div id="create-payment-voucher-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <div className="content-title">
                        <p>Create Payment Voucher</p>
                    </div>
                    <div className="content-box payee-details">
                        <p className="title">Payee Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Payee Type</label>
                                    <select className="form-control" name="PayeeType" id="PayeeType" onChange={(e) => handleUpdate(e)}>
                                        <option value="">---Select Payee Type---</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Payee Code</label>
                                    <input type="text" className="form-control form-text" id="PayeeCode" name="PayeeCode" placeholder="Enter Payee Code" onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Payee Name</label>
                                    <input type="text" className="form-control form-text" id="PayeeName" name="PayeeName" placeholder="Enter Payee Name" onChange={(e) => handleUpdate(e)} />
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Item</label>
                                    <select className="form-control" name="PaymentItem" id="PaymentItem" onChange={(e) => handleUpdate(e)}>
                                        <option value="">---Select Item Type--- </option>
                                        <option value="Commission">Commission</option>
                                        <option value="Claim">Claim</option>
                                        <option value="Refund" >Refund</option>
                                        <option value="WTH">WTH</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Reference Number</label>
                                    <input type="text" className="form-control form-text" id="ItemRef" name="ItemRef" onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Cost/Profit Centre</label>
                                    <select className="form-control" name="CostProfitCenter" id="CostProfitCenter" onChange={(e) => handleUpdate(e)} >
                                        <option value="">--Select Here--</option>
                                        <option value="HO1UWB00">HO1UWB00</option>
                                        <option value="HO0FIN00">HO0FIN00</option>
                                        <option value="HO0ACC00">HO0ACC00</option>
                                    </select>
                                </div>
                                <br></br>
                                <br></br>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Date</label>
                                    <input type="date" className="form-control form-text" id="PaymentDate" name="PaymentDate" placeholder="DD-MM-YYYY" onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Due Date</label>
                                    <input type="date" className="form-control form-text" id="DueDate" name="DueDate" placeholder="DD-MM-YYYY" onChange={(e) => handleUpdate(e)} />
                                </div>
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Payment Description</label>
                                    <input type="text" className="form-control form-text" id="Remarks" name="Remarks" placeholder="Enter Here..." onChange={(e) => handleUpdate(e)} />
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                        </div>
                        <br></br>
                    </div>
                    <br></br>
                    {
                        payeeDetails.PaymentItem && (
                            <div className="content-box receipt-details" id="commission">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 title ">
                                        <p>{payeeDetails.PaymentItem} Details</p>
                                        {/* <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button> */}
                                    </div>
                                </div>

                                {
                                    payeeDetails.PaymentItem === 'Commission' && (
                                        <>
                                            <div className="receipt-table-container">
                                                <div className="col-md-12 mb-2 ">
                                                    <table className="table table-striped receipt-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="first-column">Ref #</th>
                                                                <th scope="col">Receipt Number</th>
                                                                <th scope="col">Insured Name</th>
                                                                <th scope="col">Premium</th>
                                                                <th scope="col">Commission</th>
                                                                <th scope="col">Tax</th>
                                                                <th scope="col">VAT</th>
                                                                <th scope="col">WTH</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                            {
                                                                lineItemsDetails.length > 0 && lineItemsDetails.map((item, index) => (
                                                                    <tr>
                                                                        <td className="first-column">{item.PolicyNo}</td>
                                                                        <td>{item.ItemNo2}</td>
                                                                        <td>{item.InsuredName}</td>
                                                                        <td>{item.ItemAmount}</td>
                                                                        <td>{item.ItemAmount2}</td>
                                                                        <td>{item.TaxEximpt}</td>
                                                                        <td>
                                                                            <div className="clickable-text">
                                                                                +Add VAT Details
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="clickable-text">
                                                                                +Add WTH Details
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span onClick={() => enableEditLineItem(index)}>
                                                                                <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={() => removeVoucherLineItem(index)}>
                                                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            {
                                                                showAddNew && (
                                                                    <tr>
                                                                        <td scope="col" className="first-column">
                                                                            <input type="text" className="form-control form-text" id="PolicyNo" name="PolicyNo"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.PolicyNo} placeholder="Enter Policy/Po Number" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="ItemNo2" name="ItemNo2"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemNo2} placeholder="Enter Receipt Number" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="InsuredName" name="InsuredName"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.InsuredName} placeholder="Enter Insured Name" />
                                                                        </td>

                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="ItemAmount" name="ItemAmount"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemAmount} placeholder="$ 0.00" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="ItemAmount2" name="ItemAmount2"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemAmount2} placeholder="$ 0.00" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <select className="form-control form-text" name="TaxEximpt" id="TaxEximpt"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.TaxEximpt}>
                                                                                <option value="">Select any</option>
                                                                                <option value="TAX Exlusive">TAX Exlusive</option>
                                                                                <option value="TAX Inclusive">TAX Inclusive</option>
                                                                            </select>
                                                                        </td>
                                                                        <td scope="col" className="remove-padding">
                                                                            <div className="clickable-text">
                                                                                +Add VAT Details
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col" className="remove-padding">
                                                                            <div className="clickable-text">
                                                                                +Add WTH Details
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <span onClick={addVoucherLineItem}>
                                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={addNewVoucher}>
                                                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="receipt-table-header">
                                                <div className="col-md-12 mb-2 title ">
                                                    <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    payeeDetails.PaymentItem === 'Claim' && (
                                        <>
                                            <div className="receipt-table-container">
                                                <div className="col-md-12 mb-2 ">
                                                    <table className="table table-striped receipt-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="first-column">Payment Order #</th>
                                                                <th scope="col" className="claim-column-width">Claim #</th>
                                                                <th scope="col" className="claim-column-width">Policy #</th>
                                                                <th scope="col" className="claim-column-width">Benefit</th>
                                                                <th scope="col" className="claim-column-width">Claim Date</th>
                                                                <th scope="col" className="claim-column-width">Claim Approval Date</th>
                                                                <th scope="col" className="claim-column-width">Claim Amount</th>
                                                                <th scope="col" className="claim-column-width">Tax</th>
                                                                <th scope="col" className="claim-column-width">VAT</th>
                                                                <th scope="col" className="claim-column-width">WTH</th>
                                                                <th scope="col" className="claim-column-width">Action</th>
                                                            </tr>
                                                            {
                                                                lineItemsDetails.length > 0 && lineItemsDetails.map((item, index) => (
                                                                    <tr>
                                                                        <td className="first-column">{item.ItemNo2}</td>
                                                                        <td>{item.Description}</td>
                                                                        <td>{item.PolicyNo}</td>
                                                                        <td>{item.InsuredName}</td>
                                                                        <td>{item.ItemDate}</td>
                                                                        <td>{item.ItemDate2}</td>
                                                                        <td>{item.ItemAmount}</td>
                                                                        <td>{item.TaxEximpt}</td>
                                                                        <td>
                                                                            <div className="clickable-text">
                                                                                +Add VAT Details
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="clickable-text">
                                                                                +Add WTH Details
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span onClick={() => enableEditLineItem(index)}>
                                                                                <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={() => removeVoucherLineItem(index)}>
                                                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            {
                                                                showAddNew && (
                                                                    <tr>
                                                                        <td scope="col" className="first-column claim-column-width">
                                                                            <input type="text" className="form-control form-text" id="ItemNo2" name="ItemNo2"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemNo2} placeholder="Enter Policy/Po Number" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="Description" name="Description"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.Description} placeholder="Enter Receipt Number" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="PolicyNo" name="PolicyNo"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.PolicyNo} placeholder="Enter Policy Number" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="InsuredName" name="InsuredName"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.InsuredName} placeholder="Enter Benefit" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="date" className="form-control form-text date-width" id="ItemDate" name="ItemDate"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemDate} />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="date" className="form-control form-text date-width" id="ItemDate2" name="ItemDate2"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemDate2} />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <input type="text" className="form-control form-text" id="ItemAmount" name="ItemAmount"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.ItemAmount} placeholder="$ 0.00" />
                                                                        </td>
                                                                        <td scope="col">
                                                                            <select className="form-control form-text" name="TaxEximpt" id="TaxEximpt"
                                                                                onChange={(e) => handleItemsChange(e)} value={lineItem.TaxEximpt}>
                                                                                <option value="">Select any</option>
                                                                                <option value="TAX Exlusive">TAX Exlusive</option>
                                                                                <option value="TAX Inclusive">TAX Inclusive</option>
                                                                            </select>
                                                                        </td>
                                                                        <td scope="col" className="remove-padding">
                                                                            <div className="clickable-text">
                                                                                +Add VAT Details
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col" className="remove-padding">
                                                                            <div className="clickable-text">
                                                                                +Add WTH Details
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <span onClick={addVoucherLineItem}>
                                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={addNewVoucher}>
                                                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="receipt-table-header">
                                                <div className="col-md-12 mb-2 title ">
                                                    <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    payeeDetails.PaymentItem === 'Refund' && (
                                        <>
                                            <div className="receipt-table-container">
                                                <div className="col-md-12 mb-2 ">
                                                    <table className="table table-striped receipt-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="first-column">Refund Type #</th>
                                                                <th scope="col">Endorsement #</th>
                                                                <th scope="col">Policy/PO #</th>
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Tax</th>
                                                                <th scope="col">VAT</th>
                                                                <th scope="col">WTH</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            lineItemsDetails.length > 0 && lineItemsDetails.map((item, index) => (
                                                                <tr>
                                                                    <td className="first-column">{item.ItemType}</td>
                                                                    <td>{item.ItemNo2}</td>
                                                                    <td>{item.PolicyNo}</td>
                                                                    <td>{item.ItemDate}</td>
                                                                    <td>{item.ItemAmount}</td>
                                                                    <td>{item.Description}</td>
                                                                    <td>{item.TaxEximpt}</td>
                                                                    <td>
                                                                        <div className="clickable-text">
                                                                            +Add VAT Details
                                                                            </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="clickable-text">
                                                                            +Add WTH Details
                                                                            </div>
                                                                    </td>
                                                                    <td>
                                                                        <span onClick={() => enableEditLineItem(index)}>
                                                                            <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                        </span>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={() => removeVoucherLineItem(index)}>
                                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            showAddNew && (
                                                                <tr>
                                                                    <td scope="col" className="first-column claim-column-width">
                                                                        <select className="form-control form-text" name="ItemType" id="ItemType"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.ItemType}>
                                                                            <option value="">Select any</option>
                                                                            <option value="Cancel Policy">Cancel Policy</option>
                                                                            <option value="Double Policy">Double Policy</option>
                                                                        </select>
                                                                    </td>
                                                                    <td scope="col">
                                                                        <input type="text" className="form-control form-text" id="ItemNo2" name="ItemNo2"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.ItemNo2} placeholder="Enter Policy/Po Number" />
                                                                    </td>
                                                                    <td scope="col">
                                                                        <input type="text" className="form-control form-text" id="PolicyNo" name="PolicyNo"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.PolicyNo} placeholder="Enter Policy Number" />
                                                                    </td>
                                                                    <td scope="col">
                                                                        <input type="date" className="form-control form-text date-width" id="ItemDate" name="ItemDate"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.ItemDate} />
                                                                    </td>
                                                                    <td scope="col">
                                                                        <input type="text" className="form-control form-text" id="ItemAmount" name="ItemAmount"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.ItemAmount} placeholder="$ 0.00" />
                                                                    </td>
                                                                    <td scope="col">

                                                                        <input type="text" className="form-control form-text" id="Description" name="Description"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.Description} placeholder="Enter Receipt Number" />
                                                                    </td>
                                                                    <td scope="col">
                                                                        <select className="form-control form-text" name="TaxEximpt" id="TaxEximpt"
                                                                            onChange={(e) => handleItemsChange(e)} value={lineItem.TaxEximpt}>
                                                                            <option value="">Select any</option>
                                                                            <option value="TAX Exlusive">TAX Exlusive</option>
                                                                            <option value="TAX Inclusive">TAX Inclusive</option>
                                                                        </select>
                                                                    </td>
                                                                    <td scope="col" className="remove-padding">
                                                                        <div className="clickable-text">
                                                                            +Add VAT Details
                                                                            </div>
                                                                    </td>
                                                                    <td scope="col" className="remove-padding">
                                                                        <div className="clickable-text">
                                                                            +Add WTH Details
                                                                            </div>
                                                                    </td>
                                                                    <td scope="col">
                                                                        <span onClick={addVoucherLineItem}>
                                                                            <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                                        </span>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span onClick={addNewVoucher}>
                                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="receipt-table-header">
                                                <div className="col-md-12 mb-2 title ">
                                                    <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    payeeDetails.PaymentItem === 'WTH' && (
                                        <>
                                            <div className="receipt-table-container">
                                                <div className="col-md-12 mb-2 ">
                                                    <table className="table table-striped receipt-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="first-column">Item Type</th>
                                                                <th scope="col">Item #</th>
                                                                <th scope="col">Deduct/Pay Date #</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Remark</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="receipt-table-header">
                                                <div className="col-md-12 mb-2 title ">
                                                    <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        )
                    }


                    {
                        showCommissionTable && (
                            <div className="content-box receipt-details">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <p>Commission Details</p>
                                        <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button>
                                    </div>
                                </div>
                                <div className="receipt-table-container">
                                    <div className="col-md-12 mb-2 ">
                                        <table className="table table-striped receipt-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Policy/PO#</th>
                                                    <th scope="col">Receipt Number</th>
                                                    <th scope="col">Insured Name</th>
                                                    <th scope="col">Premium($)</th>
                                                    <th scope="col">Commission($)</th>
                                                    <th scope="col">Tax Abitlity</th>
                                                    <th scope="col">VAT </th>
                                                    <th scope="col">WTH </th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    false && (
                                                        <tr>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="PolicyNumber" name="PolicyNumber" placeholder="Enter Policy/Po Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="ReceiptNumber" name="ReceiptNumber" placeholder="Enter Receipt Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="InsuredName" name="InsuredName" placeholder="Enter Receipt Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Premium" name="Premium" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>

                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Commission" name="Commission" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="TaxAbitlity" id="TaxAbitlity" placeholder="Tax Exempt">
                                                                        <option value="">TAX Exlusive</option>
                                                                        <option value="">TAX Inclusive</option>
                                                                        <option value="">No Tax</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add VAT Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add WTH Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <span onClick={showTestEdit}>
                                                                    <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    showAddNew && (
                                                        <></>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {
                        showClaimTable && (
                            <div className="content-box receipt-details">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <p>Claim Details</p>
                                        <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button>
                                    </div>
                                </div>
                                <div className="receipt-table-container">
                                    <div className="col-md-12 mb-2 ">
                                        <table className="table table-striped receipt-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Payment Order #</th>
                                                    <th scope="col">Claim#</th>
                                                    <th scope="col">Policy #</th>
                                                    <th scope="col">Benefit</th>
                                                    <th scope="col">Claim Date</th>
                                                    <th scope="col-md-2">Claim Approval Date</th>
                                                    <th scope="col">Claim Amount</th>
                                                    <th scope="col">Tax  </th>
                                                    <th scope="col">VAT </th>
                                                    <th scope="col">WTH </th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    false && (

                                                        <tr>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="PaymentOrder#" name="PaymentOrder#" placeholder="Enter Payment Order Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="ClaimNumber" name="ClaimNumber" placeholder="Enter Claim Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Policy#" name="Policy#" placeholder="Enter Policy Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="Benifit" id="Benifit">
                                                                        <option value="">Select</option>
                                                                        <option value="Bank Transfer">Medical expenses</option>
                                                                        <option value="Cheque"></option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-10 mb-2 float-left">
                                                                    <input type="date" className="form-control form-text" id="ClaimDate" name="ClaimDate" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-10 mb-2 float-left">
                                                                    <input type="date" className="form-control form-text" id="ClaimApprovalDate" name="ClaimApprovalDate" />
                                                                </div>
                                                            </td>

                                                            <td scope="col">
                                                                <div className="col-md-10 mb-2 float-left">
                                                                    <input type="number" className="form-control form-text" id="ClaimAmount" name="ClaimAmount" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="TaxAbitlity" id="TaxAbitlity" placeholder="Tax Exempt">
                                                                        <option value="">TAX Exlusive</option>
                                                                        <option value="">TAX Inclusive</option>
                                                                        <option value="">No Tax</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add VAT Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add WTH Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <span onClick={showTestEdit}>
                                                                    <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {showAddNew && (

                                                    <tr>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="PaymentOrder#" name="PaymentOrder#" placeholder="Enter Payment Order Number" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="ClaimNumber" name="ClaimNumber" placeholder="Enter Claim Number" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="Policy#" name="Policy#" placeholder="Enter Policy Number" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className=" col-md-12 mb-2 float-left">
                                                                <select className="form-control" name="Benifit" id="Benifit">
                                                                    <option value="">Select</option>
                                                                    <option value="Bank Transfer">Medical expenses</option>
                                                                    <option value="Cheque"></option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-10 mb-2 float-left">
                                                                <input type="date" className="form-control form-text" id="ClaimDate" name="ClaimDate" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-10 mb-2 float-left">
                                                                <input type="date" className="form-control form-text" id="ClaimApprovalDate" name="ClaimApprovalDate" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-10 mb-2 float-left">
                                                                <input type="number" className="form-control form-text" id="ClaimAmount" name="ClaimAmount" placeholder="$ 0.00" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <select className="form-control" name="TaxAbitlity" id="TaxAbitlity" placeholder="Tax Exempt">
                                                                    <option value="">TAX Exlusive</option>
                                                                    <option value="">TAX Inclusive</option>
                                                                    <option value="">No Tax</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                +Add VAT Details
                                                                </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                +Add WTH Details
                                                                </div>
                                                        </td>
                                                        <td scope="col">
                                                            <span onClick={showTestEdit}>
                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        showRefundTable && (
                            <div className="content-box receipt-details">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <p>Refund Details</p>
                                        <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button>
                                    </div>
                                </div>
                                <div className="receipt-table-container">
                                    <div className="col-md-12 mb-2 ">
                                        <table className="table table-striped receipt-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Refund Type</th>
                                                    <th scope="col">Endorsement#</th>
                                                    <th scope="col">Policy/PO #</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Tax  </th>
                                                    <th scope="col">VAT </th>
                                                    <th scope="col">WTH </th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    false && (

                                                        <tr>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="RefundType" id="RefundType">
                                                                        <option value="">---Select Refund Type---</option>
                                                                        <option value="Bank Transfer">Cancel Policy</option>
                                                                        <option value="Cheque">Double Payment</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Endrosment#" name="Endrosment#" placeholder="Enter Endrosment Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="PolicyNumber" name="PolicyNumber" placeholder="Enter Policy/Po Number" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="date" className="form-control form-text" id="RefundDate" name="RefundDate" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>

                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="RefundAmount" name="RefundAmount" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Description" name="Description" placeholder="Enter Description" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="TaxAbitlity" id="TaxAbitlity" placeholder="Tax Exempt">
                                                                        <option value="">TAX Exlusive</option>
                                                                        <option value="">TAX Inclusive</option>
                                                                        <option value="">No Tax</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add VAT Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    +Add WTH Details
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <span onClick={showTestEdit}>
                                                                    <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;

                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {showAddNew && (

                                                    <tr>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <select className="form-control" name="RefundType" id="RefundType">
                                                                    <option value="">---Select Refund Type---</option>
                                                                    <option value="Bank Transfer">Cancel Policy</option>
                                                                    <option value="Cheque">Double Payment</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="Endrosment#" name="Endrosment#" placeholder="Enter Endrosment Number" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="PolicyNumber" name="PolicyNumber" placeholder="Enter Policy/Po Number" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="date" className="form-control form-text" id="RefundDate" name="RefundDate" placeholder="Enter Payee Code" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="RefundAmount" name="RefundAmount" placeholder="$ 0.00" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="Description" name="Description" placeholder="Enter Description" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <select className="form-control" name="TaxAbitlity" id="TaxAbitlity" placeholder="Tax Exempt">
                                                                    <option value="">TAX Exlusive</option>
                                                                    <option value="">TAX Inclusive</option>
                                                                    <option value="">No Tax</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                +Add VAT Details
                                                                </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                +Add WTH Details
                                                                </div>
                                                        </td>
                                                        <td scope="col">
                                                            <span onClick={showTestEdit}>
                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        showWTHTable && (
                            <div className="content-box receipt-details">
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <p>WTH Details</p>
                                        <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New CSV File</button>
                                    </div>
                                </div>
                                <div className="receipt-table-container">
                                    <div className="col-md-12 mb-2 ">
                                        <table className="table table-striped receipt-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Item Type</th>
                                                    <th scope="col">Item#</th>
                                                    <th scope="col">Deduct/Pay Date</th>
                                                    <th scope="col-md-2">Amount</th>
                                                    <th scope="col">Remark</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    false && (

                                                        <tr>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <select className="form-control" name="ItemType" id="ItemType">
                                                                        <option value="">---Select Item Type---</option>
                                                                        <option value="Bank Transfer">Receipt</option>
                                                                        <option value="Cheque">Payment Voucher</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Item#" name="Item#" placeholder="Item #" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-10 mb-2 float-left">
                                                                    <input type="date" className="form-control form-text" id="PayDate" name="PayDate" />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-8 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="WTHAmount" name="WTHAmount" placeholder="$ 0.00" />
                                                                </div>
                                                            </td>

                                                            <td scope="col">
                                                                <div className="col-md-12 mb-2 float-left">
                                                                    <input type="text" className="form-control form-text" id="Remark" name="Remark" placeholder="Enter Here..." />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <span onClick={showTestEdit}>
                                                                    <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {showAddNew && (

                                                    <tr>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <select className="form-control" name="ItemType" id="ItemType">
                                                                    <option value="">---Select Item Type---</option>
                                                                    <option value="Bank Transfer">Receipt</option>
                                                                    <option value="Cheque">Payment Voucher</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="Item#" name="Item#" placeholder="Item #" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-10 mb-2 float-left">
                                                                <input type="date" className="form-control form-text" id="PayDate" name="PayDate" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-8 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="WTHAmount" name="WTHAmount" placeholder="$ 0.00" />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <div className="col-md-12 mb-2 float-left">
                                                                <input type="text" className="form-control form-text" id="Remark" name="Remark" placeholder="Enter Here..." />
                                                            </div>
                                                        </td>
                                                        <td scope="col">
                                                            <span onClick={showTestEdit}>
                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                        </td>

                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="receipt-table-header">
                                    <div className="col-md-12 mb-2 title ">
                                        <button type="button" className="btn btn-outline-dark float-left " onClick={addNewVoucher}>+ Add New</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className="bottom">
                    <button id="continue" className="button-common primary-button" onClick={goToPayeeDetails}>
                        Next
                    </button>
                    <button id="SaveAsDraft" className="button-common btn btn-outline-primary"><b>Save as Draft</b></button>
                    <button id="CreateRCancel" className="button-common btn btn-outline-dark">Cancel</button>
                </div>
            </section>

            {/*
                showSucessModal &&(
                <div id="myModal" className="modal">
                    <div className="modal-content">
                        <p className="title">Upload Document</p>
                        <div className="form-group">
                            <div className="col-md-4 mb-1 float-left">
                                <label>Payment Description</label>
                                <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Document Name" />
                            </div>
                        </div>
                        <div className="box">
                            <label><b>Drag & Drop File Here</b><br></br>CLick to Upload a CSV file to add Members</label>
                            <button type="button" className="btn btn-outline-dark upload-csv-button">Upload New CSV File</button>
                        </div>
                        <button className="col-md-3 mb-2 float right">Upload</button>
                        <button className="col-md-3 mb-2 float right">Cancel</button>
                    </div>
                </div> )
   
            }


            {/*
                isLoading && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fill-rule="evenodd" stroke-width="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
                */}
        </div>
    )

}

export default CreateReceiptVoucher;
