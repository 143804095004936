import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ModifyVoucher2.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ModifyVoucher2 = () => {

    return (
        <div id="apply-premium-container">
            <ReceiptHeader />
            <section>
                <div className="container-4">
                    <p id="a">Modify Receipt   /   736256372927362</p>
                    <div className="">
                        <div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#">Voucher</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="#">Commission Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Payment Details</a>
                                </li>
                            </ul>
                        </div>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td id="c">Receipt Number</td>
                                    <td>736256372927362</td>
                                </tr>
                                <tr>
                                    <td id="c">Payee Type</td>
                                    <td>Agent</td>
                                </tr>
                                <tr>
                                    <td id="c">Payee Code</td>
                                    <td>1598745</td>
                                </tr>
                                <tr>
                                    <td id="c">Payee Name</td>
                                    <td>Jeffrey Winger</td>
                                </tr>
                                <tr>
                                    <td id="c"><b>Item</b></td>
                                    <td><b>Commission</b></td>
                                </tr><tr>
                                    <td id="c">Reference  Number</td>
                                    <td>Agent</td>
                                </tr><tr>
                                    <td id="c">Account</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td id="c">Cost/Profit Centre</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td id="c">Date</td>
                                    <td>2020-12-26</td>
                                </tr><tr>
                                    <td id="c">Due Date</td>
                                    <td>J2021-01-10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </section>
        </div >

    )

}

export default ModifyVoucher2;
