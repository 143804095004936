import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllReceipts.css';
// import EditableTable from './EditableTable';

const AllReceipts = () => {

    interface receiptLineItemsProp {
        DepositeAmount: number;
    }
    interface receiptProps {
        ReceiptNumber: string;
        ReceiptDetail: receiptLineItemsProp[];
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        ReceiptDate: string;
        ReceiptStatus: string;
        Amount: number;
        isSelected: boolean;
    }

    let tReceiptsList1: receiptProps = {
        ReceiptNumber: "",
        ReceiptDetail: [],
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        ReceiptDate: "",
        ReceiptStatus: "",
        Amount: 0,
        isSelected: false
    };

    interface searchDataProps {
        search_value: string;
        search_by: string;
        search_from_date: string;
        search_to_date: string;
    }

    const today = new Date();
    const toDate = `${today.toISOString().split("T")[0]}`;
    today.setMonth(today.getMonth() - 1);
    const fromDate = `${today.toISOString().split("T")[0]}`;

    let formData: searchDataProps = {
        search_value: "",
        search_by: "ReceiptDate",
        search_from_date: fromDate,
        search_to_date: toDate
    };

    const [searchData, setSearchData] = useState<searchDataProps>(formData);
    const [allReceiptsList, setAllReceiptsList] = useState<receiptProps[]>([]);
    const [tReceiptsList, setTReceiptsList] = useState<receiptProps[]>([]);
    const [showReceiptTable, setShowReceiptTable] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>('All');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        searchReceipt();
        console.log('this is url', endPoint);
    }, []);

    const goToReceiptDetail = (rec: receiptProps) => {
        //@ts-ignore
        window.receiptData = rec;
        navigate('/ViewReceipt');
    };

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        formData[name] = value;
        const newFormData = { ...searchData };
        // @ts-ignore
        newFormData[name] = value;
        if (newFormData.search_from_date && newFormData.search_to_date) {
            newFormData.search_by = 'ReceiptDate';
        }
        setSearchData(newFormData);
    }

    const handleCheckBoxChange = (e: any, rNo: string) => {
        const { checked, name, id } = e.target;
        const newRList = [...tReceiptsList];
        const premiumAppliedReceipts: receiptProps[] = [];
        newRList.map(rl => {
            if (rl.isSelected) {
                premiumAppliedReceipts.push(rl);
            }
            if (rl.ReceiptNumber === rNo || rNo === 'ALL') {
                rl.isSelected = checked;
                premiumAppliedReceipts.push(rl);
            }
        });
        // @ts-ignore
        window.APselectedReceiptData = premiumAppliedReceipts;
        setTReceiptsList(newRList);
    }

    const calculateAmount = (data: any) => {
        const newData = { ...data };
        newData.Amount = 0;
        newData.ReceiptDetail.map((el: any) => {
            newData.Amount = newData.Amount + el.DepositeAmount;
        });
        return newData;
    }

    const searchReceipt = () => {
        let searchStr = "";
        const fromDate = searchData.search_from_date;
        const toDate = searchData.search_to_date;

        if (searchData.search_by !== 'Receipt_Number') {
            searchStr += `${searchData.search_by}/`;
        }
        if (searchData.search_by !== 'Receipt_Number') {
            if (fromDate && toDate) {
                searchStr += `${fromDate}_${toDate}`;
            } else if ((fromDate && !toDate) || (!fromDate && toDate)) {
                searchStr += `${fromDate}${toDate}`
            }
        } else {
            searchStr += searchData.search_value;
        }

        if (searchStr) {
            setIsLoading(true);
            try {
                fetch(`${endPoint.getReceipt}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        console.log('this is the response form the API', response);
                        setIsLoading(false);
                        if (response && (response.ReceiptNumber || response.length)) {
                            const receiptList = ((searchData.search_by === 'Receipt_Number') ? [response] : response).map((rec: receiptProps) => {
                                rec.isSelected = false;
                                return calculateAmount(rec);
                            });
                            setAllReceiptsList(receiptList);
                            setTReceiptsList(receiptList);
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        } else {
            console.log('Search value is empty');
        }
    }

    const filterReceipt = (tab: string) => {
        setCurrentTab(tab);
        const allRec = [...allReceiptsList];
        const newRec = allRec.filter(rec => {
            return (tab === 'All') ? true : rec.ReceiptStatus === tab;
        });
        setTReceiptsList(newRec);
    }

    const clearFilter = () => {
        formData = {
            search_value: "",
            search_by: "",
            search_from_date: "",
            search_to_date: ""
        };
        setSearchData(formData);
        setTReceiptsList([]);
    }

    return (
        <div id="all-receipts-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">

                    <div className="content-title">
                        <p>All Receipts <button id="receipt-search" className="btn btn-outline-dark float-right">+Create New</button></p>
                    </div>
                    <br />
                    <div className="col-md-12 receipt-details-p2">
                        <label className={`receipt-tab ${(currentTab === 'All') ? 'tab-active' : ''}`} onClick={() => filterReceipt('All')}>
                            All
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Draft') ? 'tab-active' : ''}`} onClick={() => filterReceipt('Draft')}>
                            Drafts
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Review') ? 'tab-active' : ''}`} onClick={() => filterReceipt('Review')}>
                            Awaiting Approval
                        </label>

                    </div>
                    <br /><br />

                    <div className="content-box payee-details">
                        <p className="title"></p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Search Receipt By</label>
                                    <input type="text" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_value" name="search_value" placeholder="Begin your search" value={searchData.search_value} />
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label >&nbsp;</label>
                                    <select className="form-control" name="search_by" id="search_by" onChange={(e) => handleUpdate(e)} value={searchData.search_by}>
                                        <option value="">Search By</option>
                                        <option value="Receipt_Number">Receipt Number</option>
                                        <option value="Payee_Code">Payee Code</option>
                                    </select>
                                </div>
                                <div className="float-left or-container">
                                    <p className="">Or</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left">
                                    <label>Date Range</label>
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_from_date" name="search_from_date" placeholder="YYYY/MM/DD" />
                                </div>
                                <div className="float-left or-container">
                                    <p className="">To</p>
                                </div>
                                <div className="col-md-2 mb-2 float-left search-to">
                                    <input type="date" onChange={(e) => handleUpdate(e)} className="form-control form-text" id="search_to_date" name="search_to_date" placeholder="YYYY/MM/DD" />
                                </div>
                                <div className="col-md-3 mb-2 float-left search-to">
                                    <button id="receipt-search" className="primary-button" onClick={searchReceipt}>Search</button>
                                    <button id="clear-filter" className="btn btn-outline-dark" onClick={clearFilter}>Clear filter</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                            <div className="col-md-12 mb-2 title ">
                                <p> ({tReceiptsList.length}) Receipts </p>
                            </div>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Receipt #</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payee Type</th>
                                            <th scope="col">Payee Code</th>
                                            <th scope="col">Payee Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Receipt Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tReceiptsList.map(rec => (
                                                <tr key={`receipt-container-${rec.ReceiptNumber}`}>
                                                    <td className="receipt-number" onClick={() => goToReceiptDetail(rec)}>{rec.ReceiptNumber}</td>
                                                    <td>{rec.Amount}</td>
                                                    <td>{rec.PayeeType}</td>
                                                    <td>{rec.PayeeCode}</td>
                                                    <td>{rec.PayeeName}</td>
                                                    <td>{rec.ReceiptDate}</td>
                                                    <td>{rec.ReceiptStatus}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div className="footer">
                            <div className="dropdown">
                                Rows per page
                        <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                    <li><a href="#"></a></li>
                                    <li><a href="#"></a></li>
                                </ul>
                                &nbsp;&nbsp;
                                1-10 of 15
                                &nbsp;&nbsp;
                        <a href="#" className="previous">&#8249;</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" className="next">&#8250;</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                        </div>
                    </div>
                </div>
                {/* <div className="bottom">
                    <button id="continue">Create Receipt</button>
                </div> */}
            </section>
            {
                isLoading && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fillRule="evenodd" strokeWidth="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default AllReceipts;
