import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { getVoucherCreateData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreatePaymentVoucher2.css';


const CreatePaymentVoucher2 = () => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'THB',
    });
    interface payoutDetailsProps {
        Type: string;
        Method: string;
        BankName: string;
        BankCode: string;
        BranchCode: string;
        BranchName: string;
        AccountNumber: string;
        RCCode: string;
        Status: string;
        StatusDate: string;
        OldPVNumber: string;
    }
    let payoutDetailsForm: payoutDetailsProps = {
        Type: "",
        Method: "",
        BankCode: "",
        BankName: "",
        BranchCode: "",
        BranchName: "",
        AccountNumber: "",
        RCCode: "",
        Status: "",
        StatusDate: "",
        OldPVNumber: ""
    }
    interface PayeeAccountProps {
        PaymentRefNumber: string;
        AccountHolderName: string;
        AccountNumber: string;
        BankName: string;
        PayeeName: string;
        Branch: string;
        IBAN: string;
        ChequeType: string;
        PayAmount: string;
        AccountNumber2: string;
        PayDate: string;
        BranchCode: string;
        BranchName: string;
        PayRemarks: string;
    }

    let payeeAccountForm: PayeeAccountProps = {
        PaymentRefNumber: "",
        PayeeName: "",
        AccountHolderName: "",
        AccountNumber: "",
        BankName: "",
        Branch: "",
        IBAN: "",
        PayAmount: "0",
        ChequeType: "",
        AccountNumber2: "",
        PayDate: "",
        BranchCode: "",
        BranchName: "",
        PayRemarks: ""
    }

    interface payeeDetailsProps {
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        PaymentItem: string;
        ItemRef: string;
        CostProfitCenter: string;
        PaymentDate: string;
        DueDate: string;
        Remarks: string;
        TotalAmount: number;
        TotalVAT: number;
        TotalWTH: number;
    }
    let payeeFormDetails: payeeDetailsProps = {
        PayeeType: "",
        PayeeCode: "",
        PayeeName: "",
        PaymentItem: "",
        ItemRef: "",
        CostProfitCenter: "",
        PaymentDate: "",
        DueDate: "",
        Remarks: "",
        TotalAmount: 0,
        TotalVAT: 0,
        TotalWTH: 0
    }

    interface itemDetailsProps {
        ItemNo: string;
        ItemNo2: string;
        ItemAmount: string;
        ItemAmount2: string;
        InsuredName: string;
        PolicyNo: string;
        TaxEximpt: string;
        VAT: number;
        WTH: number;
        Description: string;
        ItemDate: string;
        ItemDate2: string;
        ItemType: ""

    }
    let itemDetailsProps: itemDetailsProps = {
        ItemNo: "",
        ItemNo2: "",
        ItemAmount: "",
        ItemAmount2: "",
        InsuredName: "",
        PolicyNo: "",
        TaxEximpt: "",
        VAT: 0,
        WTH: 0,
        Description: "",
        ItemDate: "",
        ItemDate2: "",
        ItemType: ""
    }

    const [showSummaryTable, setShowSummaryTable] = useState<boolean>(false);
    const [showWTHSummaryTable, setShowWTHSummaryTable] = useState<boolean>(false);
    const [showPayChequeDetails, setShowPayChequeDetails] = useState<boolean>(true);
    const [payoutDetails, setPayoutDetails] = useState<payoutDetailsProps>(payoutDetailsForm);
    const [payeeAccountDetails, setPayeeAccountDetails] = useState<PayeeAccountProps>(payeeAccountForm);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [voucherNumber, setVoucherNumber] = useState<string>("");
    const [voucherHeaderDetails, setVoucherHeaderDetails] = useState<payeeDetailsProps>(payeeFormDetails);
    const [voucherLineItems, setVoucherLineItems] = useState<itemDetailsProps>(itemDetailsProps);

    useEffect(() => {
        //@ts-ignore
        const { CreateVoucherPayeeDetails, CreateVoucherLineItems } = window;
        setVoucherHeaderDetails(CreateVoucherPayeeDetails);
        setVoucherLineItems(CreateVoucherLineItems);
    }, []);

    const handleUpdate = (e: any, infoType: string) => {
        const { name, value } = e.target;
        if (infoType === 'payout') {
            const payout = { ...payoutDetails };
            //@ts-ignore
            payout[name] = value;
            setPayoutDetails(payout);
        } else {
            const payeeAcc = { ...payeeAccountDetails };
            //@ts-ignore
            payeeAcc[name] = value;
            setPayeeAccountDetails(payeeAcc);
        }

        // setShowSummaryTable(false);
        // setShowWTHSummaryTable(false);


        // if (value === 'commission') {
        //     setShowSummaryTable(true);
        // } else if (value === 'claim') {
        //     setShowSummaryTable(true);
        // } else if (value === 'refund') {
        //     setShowSummaryTable(true);
        // } else if (value === 'wth') {
        //     setShowWTHSummaryTable(true);
        // }

        // setShowPayChequeDetails(false);
        // if (value === 'cheque') {
        //     setShowPayChequeDetails(true);
        // } else if (value === 'banktransfer') {
        //     setShowPayChequeDetails(false);
        // }
    }

    const submitVoucher = () => {
        const voucherData = getVoucherCreateData(voucherHeaderDetails, voucherLineItems, payoutDetails, payeeAccountDetails);
        fetch(`${endPoint.createVoucher}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(voucherData)
        })
            .then((response: any) => response.json())
            .then((response: any) => {
                console.log(response);
                setVoucherNumber(response)
                setShowSuccessModal(true);
            });
    }

    const closeModal = () => {
        setShowSuccessModal(false);
    }

    const copyVoucherNumber = () => {
        const text = voucherNumber;
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    console.log('this is render', voucherHeaderDetails);
    return (
        <div id="all-vouchers-container">
            <ReceiptHeader />
            <section>
                <div className="content-container">
                    <p>Create Payment Voucher  /  Step 2</p>

                    <div className="content-box receipt-details">
                        <p className="title">Summary</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left pad-left">
                                    <label >VAT Amount</label>
                                    <p>{formatter.format(voucherHeaderDetails.TotalVAT)}</p>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>WTH Amount</label>
                                    <p>{formatter.format(voucherHeaderDetails.TotalWTH)}</p>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>Total Amount</label>
                                    <p>{formatter.format(voucherHeaderDetails.TotalAmount)}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className="content-box receipt-details">
                        <p className="title">Summary</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Item Count</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Item(s)</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Claim</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Output VAT(selling)</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht</p>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Sum of Premium</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Commission</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Output VAT(buying)</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht</p>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Sum of Premium</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Others</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Sum of Withholding Tax</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht</p>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="form-group">
                                <div className="col-md-4 mb-1 float-left">
                                    <label>Details</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Other Expense</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                                <div className="col-md-3 mb-1 float-left">
                                    <label>Grand Total</label>
                                    <input type="text" className="form-control form-text" id="search_value" name="search_value" placeholder="Enter Here..." />
                                </div>
                                <div className="float-left or-container">
                                    <p className=""><br></br><br></br>Baht</p>
                                </div>
                            </div>
                            <br /> <br /> <br /> <br /> <br /> <br />
                        </div>
                        <br></br>
                    </div> */}


                    <div className="content-box payment-details">
                        <p className="title">Payment Details</p>
                        <div className="">
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <div className="col-md-12 float-left">
                                        <label >Payout Type</label>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <select className="form-control" name="PayoutType" id="PayoutType" onChange={(e) => handleUpdate(e, 'payout')}>
                                            <option value="">--- SelectPayout Type ---</option>
                                            <option value="cheque">Cheque</option>
                                            <option value="banktransfer">Bank Transfer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <div className="col-md-12 float-left">
                                        <label >Bank Code</label>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text"
                                                id="BankCode" name="BankCode" placeholder="0.00" onChange={(e) => handleUpdate(e, 'payout')} />
                                        </div>
                                        <div className="col-md-10 mb-2 float-left">
                                            <input type="text" className="form-control form-text"
                                                id="BankName" name="BankName" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <div className="col-md-12 float-left">
                                        <label >Branch Code</label>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text"
                                                id="BranchCode" name="BranchCode" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                        </div>
                                        <div className="col-md-10 mb-2 float-left">
                                            <input type="text" className="form-control form-text"
                                                id="BranchName" name="BranchName" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-4 mb-2 float-left">
                                    <div className="col-md-12 float-left">
                                        <label >Account</label>
                                    </div>
                                    <div className="col-md-12 float-left">
                                        <div className="col-md-2 mb-2 float-left remove-left-pad">
                                            <input type="text" className="form-control form-text"
                                                id="AccountCode" name="AccountCode" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                        </div>
                                        <div className="col-md-10 mb-2 float-left">
                                            <input type="text" className="form-control form-text"
                                                id="AccountNumber" name="AccountNumber" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                            {/* <select className="form-control" name="AccountNumber" id="AccountNumber" onChange={(e) => handleUpdate(e, 'payout')}>
                                                <option value="">--- account number ---</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="banktransfer">Bank Transfer</option>
                                            </select> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2 float-left">
                                    <label>RC Code</label>
                                    <input type="text" className="form-control form-text"
                                        id="RCCode" name="RCCode" onChange={(e) => handleUpdate(e, 'payout')} placeholder="0.00" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        showPayChequeDetails && (

                            <div className="content-box pay-cheque-details">
                                <p className="title">Pay Cheque Details</p>
                                <div className="">
                                    <div className="form-group">
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Amount</label>
                                            <input type="text" className="form-control form-text"
                                                id="PayAmount" name="PayAmount" onChange={(e) => handleUpdate(e, 'acc')} placeholder="Enter here" />
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <label> Type of Pay Cheque*</label>
                                            <input type="text" className="form-control form-text"
                                                id="ChequeType" name="ChequeType" onChange={(e) => handleUpdate(e, 'acc')} placeholder="Enter here" />
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <div className="col-md-12 mb-2 float-left">
                                                <label>Cheque Number*</label>
                                            </div>
                                            <div className="col-md-12 mb-2 float-left">
                                                <div className="col-md-3 mb-1 float-left remove-left-pad">
                                                    <input type="text" id="AccountNumber" name="AccountNumber" onChange={(e) => handleUpdate(e, 'acc')} className="form-control form-text" />
                                                </div>
                                                <div className="col-sm-9 mb-1 float-left">
                                                    <input type="text" className="form-control form-text" id="BankName" name="BankName" onChange={(e) => handleUpdate(e, 'acc')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Payee Name*</label>
                                            <input type="text" className="form-control form-text"
                                                id="PayeeName" name="PayeeName" onChange={(e) => handleUpdate(e, 'acc')} placeholder="Enter here" />
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Name on Cheque*</label>
                                            <input type="text" className="form-control form-text"
                                                id="AccountHolderName" name="AccountHolderName" onChange={(e) => handleUpdate(e, 'acc')} placeholder="Enter here" />
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Old Document Number*</label>
                                            <input type="text" className="form-control form-text"
                                                id="PayeeOldDocNumber" name="PayeeOldDocNumber" onChange={(e) => handleUpdate(e, 'acc')} placeholder="Enter Here..." />
                                        </div>
                                        <br></br>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="form-group">
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Cheque Date</label>
                                            <input type="date" className="form-control form-text"
                                                id="PayDate" name="PayDate" placeholder="Enter here" onChange={(e) => handleUpdate(e, 'acc')} />
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <div className="col-md-12 float-left">
                                                <label >Pickup Cheque at Branch</label>
                                            </div>
                                            <div className="col-md-12 float-left">
                                                <div className="col-md-2 mb-2 float-left remove-left-pad">
                                                    <input type="text" className="form-control form-text" id="IBAN" name="IBAN"
                                                        onChange={(e) => handleUpdate(e, 'acc')} />
                                                </div>
                                                <div className="col-md-10 mb-2 float-left">
                                                    <input type="text" className="form-control form-text" id="Branch" name="Branch"
                                                        onChange={(e) => handleUpdate(e, 'acc')} />
                                                    {/* <select className="form-control" name="BranchName" id="BranchName" onChange={(e) => handleUpdate(e, 'acc')} >
                                                        <option value="">Select Branch</option>
                                                        <option value="Customer">Customer</option>
                                                        <option value="Agent">Agent</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-2 float-left">
                                            <label>Remarks</label>
                                            <input type="text" className="form-control form-text" onChange={(e) => handleUpdate(e, 'acc')}
                                                id="PayRemarks" name="PayRemarks" placeholder="Enter here" />
                                        </div>
                                        <br></br>
                                    </div>
                                    <br></br>
                                    <br></br>
                                </div>

                            </div>

                        )}

                    <br></br>

                    {/* <div className="content-box receipt-details">
                        <div className="receipt-table-header">
                            <div className="col-md-12 mb-2 title ">
                                <p>Attached Document</p>
                                <button type="button" className="btn btn-outline-dark float-right upload-csv-button">Upload New Document</button>
                            </div>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Documents</p>
                        </div>
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 ">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Document</th>
                                            <th scope="col" >File Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="col">Statement of Account</td>
                                            <td scope="col">Document 1.pdf</td>

                                            <td scope="col">
                                                <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                &nbsp;&nbsp;
                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="col">Hospital Invoice</td>
                                            <td scope="col">Document 2.pdf</td>

                                            <td scope="col">
                                                <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                &nbsp;&nbsp;
                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="bottom">
                    <button id="continue" className="button-common primary-button" onClick={submitVoucher}>Create & Submit for Approval</button>
                    <button id="savedraft" className="button-common btn btn-outline-primary">Save as Draft</button>
                    <button id="cancel" className="button-common btn btn-outline-primary">Cancel</button>
                </div>
            </section>
            {
                showSuccessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>
                                    Voucher Created
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Voucher Number
                        </span><br />
                                <span className="receipt-number">{voucherNumber}</span> <span className="receipt-copy" onClick={copyVoucherNumber} >Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt" className="primary-button" onClick={closeModal}>OK</button>
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default CreatePaymentVoucher2;
