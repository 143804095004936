import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { getApplyPremiumData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ApplyPremium.css';

const ApplyPremium4 = () => {
    interface receiptLineItemsProp {
        DepositeBy: string;
        DepositeByNo: string;
        BankCode: string;
        BankName: string;
        BankBranchName: string;
        DepositeRefNo: string;
        DepositeRefType: string;
        DepositeDate: string;
        DepositeAmount: number;
        BankFee: number;
        DepositeType: string;
        Status: string;
        isSelected: boolean;
    }

    interface receiptProps {
        ReceiptNumber: string;
        ReceiptDetail: receiptLineItemsProp[];
        PayeeType: string;
        PayeeCode: string;
        PayeeName: string;
        ReceiptDate: string;
        ReceiptStatus: string;
        Amount: number;
        isSelected: boolean;
    }

    const [tReceiptsList, setTReceiptsList] = useState<receiptProps[]>([]);
    const [selectedReceipts, setSelectedReceipts] = useState<receiptProps[]>([]);
    const [selectedDeposits, setSelectedDeposits] = useState<receiptLineItemsProp[]>([]);
    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);

    const backToApplyPremium = () => {
        navigate('/ApplyPremium');
    };

    const redirectToNextPage123 = () => {
        navigate('/ApplyPremium123');
    };

    const redirectToNextPage57 = () => {
        console.log(selectedDeposits);
        //@ts-ignore
        window.APselectedDeposits = selectedDeposits;
        navigate('/ApplyPremium57');
    };

    useEffect(() => {
        // @ts-ignore
        const selectedR = window.APselectedReceiptData.map(rec => {
            rec.ReceiptDetail.map(r => {
                r.isSelected = false;
                return r;
            });
            return rec;
        });
        setTReceiptsList(selectedR);
    }, []);

    const handleCheckBoxChange = (e: any, rNo: string, ind: number) => {
        const { checked } = e.target;
        const newRList = [...tReceiptsList];
        const selectedReceipts: receiptProps[] = [];
        const selectedDeposits: receiptLineItemsProp[] = [];
        newRList.map(rl => {
            if (rl.ReceiptNumber === rNo) {
                rl.ReceiptDetail[ind].isSelected = checked;
                //selectedDeposits.push(rl.ReceiptDetail[ind]);
            }
            if (rNo === 'ALL') {
                rl.ReceiptDetail.map(r => {
                    r.isSelected = checked;
                    //selectedDeposits.push(r);
                    return r;
                })
            }
            rl.ReceiptDetail.map(r => {
                if (r.isSelected) {
                    selectedDeposits.push(r);
                }
                return r;
            })
            return rl;
        });
        setTReceiptsList(newRList);
        setSelectedReceipts(selectedReceipts);
        setSelectedDeposits(selectedDeposits);
    }


    const callApplyPremium = () => {
        try {
            const apData = getApplyPremiumData(selectedReceipts);
            console.log(apData);
            fetch(`${endPoint.applyPremium}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(apData)
            })
                .then((response: any) => response.json())
                .then((response: any) => {
                    console.log(response);
                    setShowSucessModal(true);
                });
        } catch (exception) {
            console.log(exception);
        }
    }

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }

    return (
        <div id="apply-premium-container">
            <ReceiptHeader />
            <section>
                <div className="container-4">
                    <p id="a"><span onClick={backToApplyPremium}>Apply Premium </span>/<b>Receipts Deposit Details</b> </p>

                    <div className="col-md-12 receipt-details-p3">
                        <div className="col-md-12 receipt-details-line">
                            <label className="col-md-2 title">Receipt Number</label>
                            <label className="col-md-10 value">{(tReceiptsList.length > 0 && tReceiptsList[0].PayeeType) || '-'}</label>
                        </div>
                        <div className="col-md-12 receipt-details-line even">
                            <label className="col-md-2 title">Payee Type</label>
                            <label className="col-md-10 value">{(tReceiptsList.length > 0 && tReceiptsList[0].PayeeCode) || '-'}</label>
                        </div>
                        <div className="col-md-12 receipt-details-line">
                            <label className="col-md-2 title">Payee Code</label>
                            <label className="col-md-10 value">{(tReceiptsList.length > 0 && tReceiptsList[0].PayeeName) || '-'}</label>
                        </div>
                    </div>
                </div>
                <div className="content-box receipt-details">
                    <div className="receipt-table-header">
                        <div className="col-md-12 mb-2 title ">
                            <p> Deposit Details</p>
                        </div>
                    </div>
                    <div className="receipt-table-container">
                        <div className="col-md-12 mb-2 float-left middle-table">
                            <table className="table table-striped receipt-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" name="checkAll" id="checkAll"
                                                onChange={(e) => handleCheckBoxChange(e, 'ALL', 0)} />
                                        </th>
                                        <th scope="col">Receipt #</th>
                                        <th scope="col">Deposit By</th>
                                        <th scope="col">Deposit By #</th>
                                        <th scope="col">Bank Code</th>
                                        <th scope="col">Bank Name</th>
                                        <th scope="col">Bank Branch</th>
                                        <th scope="col">Ref #</th>
                                        <th scope="col">Ref Type</th>
                                        <th scope="col">Deposit Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fee</th>
                                        <th scope="col">VAT</th>
                                        <th scope="col">WTH</th>
                                        <th scope="col">Deposit Type</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (tReceiptsList.length > 0) && tReceiptsList.map(rec => (
                                            rec.ReceiptDetail.map((rDeposite, index) => (
                                                <tr key={`receipt-container-${rec.ReceiptNumber}-${index}`}>
                                                    <td>
                                                        <input type="checkbox" checked={rDeposite.isSelected}
                                                            name={`check-${rec.ReceiptNumber}`} id={`check-${rec.ReceiptNumber}`}
                                                            onChange={(e) => handleCheckBoxChange(e, rec.ReceiptNumber, index)} />
                                                    </td>
                                                    <td>{rec.ReceiptNumber}</td>
                                                    <td>{rDeposite.DepositeBy}</td>
                                                    <td>{rDeposite.DepositeByNo}</td>
                                                    <td>{rDeposite.BankCode}</td>
                                                    <td>{rDeposite.BankName}</td>
                                                    <td>{rDeposite.BankBranchName}</td>
                                                    <td>{rDeposite.DepositeRefNo}</td>
                                                    <td>{rDeposite.DepositeRefType}</td>
                                                    <td>{rDeposite.DepositeDate}</td>
                                                    <td>{rDeposite.DepositeAmount}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.BankFee}</td>
                                                    <td>{rDeposite.DepositeType}</td>
                                                    <td>{rDeposite.Status}</td>
                                                </tr>
                                            ))
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr />
                    <div className="footer">
                        <div className="dropdown">
                            Rows per page
                        <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                                <li><span></span></li>
                                <li><span></span></li>
                            </ul>
                            &nbsp;&nbsp;
                            1-10 of 15
                            &nbsp;&nbsp;
                        <span className="previous">&#8249;</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="next">&#8250;</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    </div>
                </div>
                <div className="bottom">
                    <button id="continue" className="primary-button" onClick={redirectToNextPage57}>Next</button>
                    <button id="continue" className="btn btn btn-outline-dark" onClick={backToApplyPremium}>Back</button>
                    {/* <button id="continue" className="primary-button" onClick={redirectToNextPage123}>redirectToNextPage123</button>
                    <button id="continue" className="btn btn btn-outline-dark" onClick={redirectToNextPage57}>redirectToNextPage57</button> */}
                </div>
            </section>

            {
                showSucessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>Premium Applied</span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>Premium applied successully !</span><br />
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt" className="primary-button">Download Voucher</button>
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default ApplyPremium4;
