import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getApplyPremiumData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ApplyPremium57.css';


const ApplyPremium57 = () => {

    const redirectToNextPage4 = () => {
        navigate('/ApplyPremiumReceipts');
    };
    const redirectToNextPage8 = () => {
        navigate('/ApplyPremium8');
    };

    interface appyReceiptsProps {
        Amount: number;
        ReceiptNumber: string;
    }

    interface searchDataProps {
        searchBy: string;
        searchValue: string;
    }


    const [selectedDeposits, setSelectedDeposits] = useState<any[]>([]);
    const [policiesList, setPoliciesList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [depositsForApplyPremium, setDepositsForApplyPremium] = useState<string[]>([]);
    const [receiptsForApplyPremium, setreceiptsForApplyPremium] = useState<appyReceiptsProps[]>([]);
    const [showSucessModal, setShowSucessModal] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<searchDataProps>({
        searchBy: '',
        searchValue: ''
    });

    useEffect(() => {
        // @ts-ignore
        const selectedDep = window.APselectedDeposits;
        // @ts-ignore
        const selectedRec = window.APselectedReceipts
        console.log('this is test', selectedDep, selectedRec);
        setSelectedDeposits(selectedDep);
        setreceiptsForApplyPremium(selectedRec);
        getPoliciesData(selectedDep);
    }, []);

    const handleUpdate = (e: any) => {
        const { name, value } = e.target;
        const formData: searchDataProps = { ...searchData };
        //@ts-ignore
        formData[name] = value;
        setSearchData(formData)
    }
    const getPoliciesData = (policies: any) => {
        try {
            setIsLoading(true);
            const newList = [...policiesList];
            policies.map((policy, index: number) => {
                fetch(`https://general.sonic.dev.coherent.com.hk/general/v2/enquiry/policy/${policy.DepositeRefNo}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response: any) => response.json())
                    .then((response: any) => {
                        response.isSelected = false;
                        newList.push(response);
                        if (policies.length === (index + 1)) {
                            setPoliciesList(newList);
                            setIsLoading(false);
                        }
                    });
            });
        } catch (exception) {
            console.log(exception);
            setIsLoading(false);
        }
    }

    const SearchPolicy = () => {
        const newPolicy = searchData.searchValue;
        if (newPolicy) {
            getPoliciesData([{
                DepositeRefNo: newPolicy
            }]);
        }
    }

    const handleCheckBoxChange = (e: any, contId: string, index: number) => {
        const { checked } = e.target;
        const newList = [...policiesList];
        debugger;
        const policiesNewList: any[] = [];
        const selectedPolicies: string[] = [];
        newList.map(pol => {
            if (pol.Cont.ContID === contId || contId === 'ALL') {
                pol.isSelected = checked;
            }
            if (pol.isSelected) {
                selectedPolicies.push(pol.Cont.ContID);
            }
            policiesNewList.push(pol);
        });
        setDepositsForApplyPremium(selectedPolicies);
        setPoliciesList(policiesNewList);
    }

    const callApplyPremium = () => {
        try {
            const apData = getApplyPremiumData(depositsForApplyPremium, receiptsForApplyPremium);
            fetch(`${endPoint.applyPremium}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(apData)
            })
                .then((response: any) => response.json())
                .then((response: any) => {
                    console.log(response);
                    if (response === "Successfull processed") {
                        setShowSucessModal(true);
                    }
                });
        } catch (exception) {
            console.log(exception);
        }
    }

    const closeReceiptModal = () => {
        setShowSucessModal(false);
    }

    return (
        <div id="apply-premium-container">
            <ReceiptHeader />
            <section>
                <div className="container-4">
                    <p id="breadcrumb-content" className="premium-breadcrumb">Apply Premium / <span onClick={redirectToNextPage4}>Receipts Deposit Details</span> / <b>Policy Details</b> </p>
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td className="header-info-title">Payee Type</td>
                                <td>Agent</td>
                            </tr>
                            <tr>
                                <td className="header-info-title">Payee Code</td>
                                <td>1598745</td>
                            </tr>
                            <tr>
                                <td className="header-info-title">Payee Name</td>
                                <td>Jeffrey Winger</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div className="content-box receipt-details">
                    <div className="receipt-table-header">
                        <div className="group-name search-policy-container">
                            <div className="col-md-1 mb-2 float-left">
                                <span>Policy Listing</span>
                            </div>
                            <div className="col-md-2 mb-2 float-left">
                                <select name="searchBy" id="searchBy" className="form-control form-text header-info-titleearch_by"
                                    onChange={(e) => handleUpdate(e)} value={searchData.searchBy}>
                                    <option value="">Select Any</option>
                                    <option value="Policy Number">Policy Number</option>
                                    <option value="--">--</option>
                                </select>
                            </div>
                            <div className="col-md-2 mb-2 float-left">
                                <input type="text" className="form-control form-text" id="searchValue" name="searchValue"
                                onChange={(e) => handleUpdate(e)} value={searchData.searchValue} placeholder="Enter Policy Number" />
                            </div>
                            <div className="col-md-2 mb-2 float-left">
                                <button id="add-policy-button" className="btn btn-outline-dark" onClick={SearchPolicy}>Add Policy Number</button>
                            </div>
                            <div className="col-md-2 mb-2 float-right">
                                <button id="clear-filter" className="btn btn-outline-dark">Upload .CSV File</button>
                            </div>
                            <br></br>
                        </div>
                        <br />
                    </div>
                    <br />
                    <div className="receipt-table-container">
                        <div className="col-md-12 mb-2 float-left middle-table">
                            <table className="table table-striped receipt-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type="checkbox" name="checkAll" id="checkAll"
                                                onChange={(e) => handleCheckBoxChange(e, 'ALL', 0)} />
                                        </th>
                                        <th>Policy Number</th>
                                        <th scope="col">Insured Name</th>
                                        <th scope="col">Payee Code</th>
                                        <th scope="col">Net Premium</th>
                                        <th scope="col">Stampduty</th>
                                        <th scope="col">Tax</th>
                                        <th scope="col">Outstanding</th>
                                        <th scope="col">Total Premium</th>
                                        <th scope="col" className="td-with-input">Settled Amount </th>
                                        <th scope="col" className="td-with-input">Discount</th>
                                        <th scope="col">Comm. Amount</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        policiesList.length > 0 && policiesList.map((policy, index) => (
                                            <tr key={`${policy.Cont.ContID}-${index}`}>
                                                <td>
                                                    <input type="checkbox" name={`check-${policy.Cont.ContID}-${index}`} id={`check-${policy.Cont.ContID}-${index}`}
                                                        onChange={(e) => handleCheckBoxChange(e, policy.Cont.ContID, index)} checked={policy.isSelected} />
                                                </td>
                                                <td>{policy.Cont.ContID}</td>
                                                <td>-</td>
                                                <td>{policy.Cont.Agent}</td>
                                                <td>${policy.Cont.BilledPremium.ModalPremium}</td>
                                                <td>${policy.Cont.BilledPremium.Gst}</td>
                                                <td>${policy.Cont.BilledPremium.Gst}</td>
                                                <td>${policy.Cont.BilledPremium.GrossAnnualPrem}</td>
                                                <td>${policy.Cont.BilledPremium.GrossAnnualPrem}</td>
                                                <td>
                                                    <input type="text" className="form-control mb-2" name={`sattledAmt${index}`} id={`sattledAmt${index}`} placeholder="00.00" />
                                                </td>
                                                <td>
                                                    <input type="text" className="form-control mb-2" name={`discountAmt${index}`} id={`discountAmt${index}`} placeholder="00.00" />
                                                </td>
                                                <td>$ 25.00</td>
                                                <td>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                        className="bi bi-x" viewBox="0 0 16 16">
                                                        <path
                                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="dropdown">
                            Rows per page
                        <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                                <li><a href="#"></a></li>
                                <li><a href="#"></a></li>
                            </ul>
                            &nbsp;&nbsp;
                            1-10 of 15
                            &nbsp;&nbsp;
                        <a href="#" className="previous">&#8249;</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" className="next">&#8250;</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    </div>
                </div>
                <div className="bottom">
                    <button id="continue" className="primary-button" onClick={callApplyPremium}>Apply</button>
                    <button id="continue" className="btn btn btn-outline-dark" onClick={redirectToNextPage4}>Back</button>
                </div>
            </section>

            {
                isLoading && (
                    <div id="myModal" className="modal">
                        <div className="loader-content">
                            <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#24c4cc" aria-label="audio-loading">
                                <g fill="none" fillRule="evenodd" strokeWidth="2">
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                    <circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite">
                                    </animate>
                                        <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite">
                                        </animate>
                                    </circle>
                                </g>
                            </svg>
                        </div>
                    </div>
                )
            }

            {
                showSucessModal && (
                    <div id="myModal" className="modal">
                        <div className="modal-content">
                            <div className="col-md-12 mb-2 download-receipt-title">
                                <span>Premium Applied</span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>Premium applied successfully !</span><br />
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt" className="primary-button" >Download Voucher</button>
                                <button id="download-receipt-close" className="btn btn-outline-dark" onClick={closeReceiptModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    )

}

export default ApplyPremium57;
