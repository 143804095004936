import React, { useEffect, useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../ReceiptHeader'
import { receiptListProps, hederDataProps } from '../interfaces';
import { getReceiptData } from '../preparePayload';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ModifyVoucher3.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const ModifyVoucher3 = () => {

    return (
        <div id="apply-premium-container">
            <ReceiptHeader />
            <section>
                <div className="container-4">
                    <br />
                    <p id="a">Modify Receipt   /   736256372927362</p>
                    <br />
                    <div className="content-box receipt-details">
                        <div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#">Voucher</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="#">Commission Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Payment Details</a>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div className="receipt-table-container">
                            <div className="col-md-12 mb-2 float-left middle-table">
                                <table className="table table-striped receipt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Policy/PO #</th>
                                            <th scope="col">Receipt Number #</th>
                                            <th scope="col">Insured Name</th>
                                            <th scope="col">Premium</th>
                                            <th scope="col">Commission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="col">S-HO1-20-PPHO4020</td>
                                            <td scope="col">568813321685</td>
                                            <td scope="col">Jeffrey Winger</td>
                                            <td scope="col">$ 1000.00</td>
                                            <td scope="col">$ 150.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div className="footer">
                            <div className="dropdown">
                                Rows per page
                            <button className="btn btn-default dropdown-toggle col-xs" type="button" data-toggle="dropdown">10
                            <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                    <li><a href="#"></a></li>
                                    <li><a href="#"></a></li>
                                </ul>
                            &nbsp;&nbsp;
                            1-10 of 15
                            &nbsp;&nbsp;
                            <a href="#" className="previous">&#8249;</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="#" className="next">&#8250;</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >

    )

}

export default ModifyVoucher3;
